import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { selectCustomerReview } from "../../../state/selectors/selectCustomerReview";
import { useSelector } from "react-redux";
import { reviews } from "../../common/enum/enum";
import { Button, Carousel, Col } from "react-bootstrap";
import styles from "./CustomerReview.module.scss";

import left_arrow_image from '../../../Assets/arrow-left.png';
import right_arrow_image from '../../../Assets/arrow-right.png';

const CustomerReviewSection: FC = () => {
  const history = useHistory();
  // const reviews = useSelector(selectCustomerReview);

    const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex: React.SetStateAction<number>) => {
      setIndex(selectedIndex);
    };
  
    const handlePrev = () => {
      setIndex((prevIndex) => (prevIndex === 0 ? reviews.length - 1 : prevIndex - 1));
    };
  
    const handleNext = () => {
      setIndex((prevIndex) => (prevIndex === reviews.length - 1 ? 0 : prevIndex + 1));
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine styles based on screen width
  const isSmallDevice = screenWidth <= 768;
  const isTablet = screenWidth > 768 && screenWidth <= 1024;

    


  return (
    // <div style={styles.section} className="reviewImage">
      // <div style={styles.header}>
      //   <h2
      //     style={{
      //       fontSize: 45,
      //       fontWeight: "400",
      //       marginBottom: 40,
      //       color: "black",
      //       textAlign: "center",
      //       position: "relative",
      //     }}
      //   >
      //     <img
      //       onClick={() => history.push("/customerReviews")}
      //       className="col-md-3 col-sm-4 col-lg-2 col-sx-2 col-6"
      //       src={require("../../../Assets/reviews.png")}
      //     />
      //     <span
      //       style={{
      //         display: "block",
      //         width: 90,
      //         height: 6,
      //         backgroundColor: "#75abdc",
      //         position: "absolute",
      //         bottom: -10,
      //         left: "50%",
      //         transform: "translateX(-50%)",
      //         borderRadius: "0px 0px 5px 5px",
      //         opacity: 0.7,
      //       }}
      //     />
      //   </h2>
      // </div>
    //   <div style={styles.reviewContainer}>
    //     {reviews.map((review, index) => (
    //       <Col key={index} style={styles.reviewCard}>
    //         <img
    //           loading="lazy"
    //           src={review.image}
    //           // alt={review.city}
    //           style={styles.reviewImage}
    //         />
    //         <div style={styles.reviewContent}>
    //           {/* <h3 style={styles.city}>{review.city}</h3>
    //           <div style={styles.stars}>{"★".repeat(review.rating)}</div> */}
    //           <p style={styles.reviewText}>{review.review}</p>
    //           <p style={styles.reviewerName}>{review.name}</p>
    //         </div>
    //       </Col>
    //     ))}
    //   </div>
    // </div>

    // <div className="container my-4">
    //   <h2 className="text-center mb-4">Customer Reviews</h2>
    //   <Carousel indicators={true} interval={3000}>
    //     {reviews.map((review, index) => (
    //       <Carousel.Item key={index}>
    //         <div
    //           className="d-flex flex-column align-items-center"
    //           style={{
    //             background: "#f8f9fa",
    //             borderRadius: "10px",
    //             padding: "30px",
    //             boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
    //           }}
    //         >
    //           <img
    //             src={review.image}
    //             alt={review.name}
    //             className="rounded-circle mb-3"
    //             style={{ width: "80px", height: "80px" }}
    //           />
    //           <p className="text-center mb-3" style={{ fontStyle: "italic" }}>
    //             "{review.review}"
    //           </p>
    //           <h5>{review.name}</h5>
    //           <p style={{ color: "#ff9900", margin: "10px 0" }}>
    //             {"★".repeat(review.rating)}{" "}
    //             {"☆".repeat(5 - review.rating)}
    //           </p>
    //         </div>
    //       </Carousel.Item>
    //     ))}
    //   </Carousel>
    // </div>
    // <div className="container my-4">
    //   <div style={styles.header}>
    //    <h2
    //       style={{
    //         fontSize: 45,
    //         fontWeight: "400",
    //         marginBottom: 40,
    //         color: "black",
    //         textAlign: "center",
    //         position: "relative",
    //       }}
    //     >
    //       <img
    //         onClick={() => history.push("/customerReviews")}
    //         className="col-md-3 col-sm-4 col-lg-2 col-sx-2 col-6"
    //         src={require("../../../Assets/reviews.png")}
    //       />
    //       <span
    //         style={{
    //           display: "block",
    //           width: 90,
    //           height: 6,
    //           backgroundColor: "#75abdc",
    //           position: "absolute",
    //           bottom: -10,
    //           left: "50%",
    //           transform: "translateX(-50%)",
    //           borderRadius: "0px 0px 5px 5px",
    //           opacity: 0.7,
    //         }}
    //       />
    //     </h2>
    //   </div>
    //   <div style={{ position: "relative" }}>
    //     <Carousel
    //       activeIndex={index}
    //       onSelect={handleSelect}
    //       controls={false} // Hide default controls
    //       indicators={false} // Hide default indicators
    //       interval={3000} // Auto-slide every 3 seconds
    //     >
    //       {reviews.map((review, idx) => (
    //         <Carousel.Item key={idx}>
    //           <div
    //             className="d-flex flex-column align-items-center"
    //             style={{
    //               background: "#ffffff",
    //               borderRadius: "10px",
    //               padding: "30px",
    //               boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
    //             }}
    //           >
    //             <img
    //               src={review.image}
    //               alt={review.name}
    //               className="rounded-circle mb-3"
    //               style={{ width: "80px", height: "80px" }}
    //             />
    //             <p className="text-center mb-3" style={{ fontStyle: "italic" }}>
    //               "{review.review}"
    //             </p>
    //             <h5>{review.name}</h5>
    //             <p style={{ color: "#ff9900", margin: "10px 0" }}>
    //               {"★".repeat(review.rating)}{" "}
    //               {"☆".repeat(5 - review.rating)}
    //             </p>
    //           </div>
    //         </Carousel.Item>
    //       ))}
    //     </Carousel>

    //     {/* Navigation Buttons */}
    //     <Button
    //       onClick={handlePrev}
    //       style={{
    //         position: "absolute",
    //         top: "50%",
    //         left: "10px",
    //         transform: "translateY(-50%)",
    //         zIndex: 10,
    //         backgroundColor:"transparent",
    //         borderColor:"transparent"

    //       }}
    //       // variant="dark"
    //     >
    //       <img
    //         src={left_arrow_image} // Add your image URL here
    //         alt="left-arrow"
    //         style={{
    //           width: "30px",  // Adjust size as needed
    //           height: "30px",
    //         }}
    //       />
    //     </Button>
    //     <Button
    //       onClick={handleNext}
    //       style={{
    //         position: "absolute",
    //         top: "50%",
    //         right: "10px",
    //         transform: "translateY(-50%)",
    //         zIndex: 10,
    //         backgroundColor:"transparent",
    //         borderColor:"transparent"
    //       }}
    //       // variant="dark"
    //     >
    //       <img
    //         src={right_arrow_image} // Add your image URL here
    //         alt="right-arrow"
    //         style={{
    //           width: "30px",  // Adjust size as needed
    //           height: "30px",
    //         }}
    //       />
    //     </Button>
    //   </div>
    // </div>
    
  //   <div className="container my-4">
  //   <div className="text-center" style={{ marginBottom: "20px" }}>
  //     <h2
  //       style={{
  //         fontSize: "2.5rem",
  //         fontWeight: "400",
  //         marginBottom: "40px",
  //         color: "black",
  //         position: "relative",
  //       }}
  //     >
  //       <img
  //         onClick={() => history.push("/customerReviews")}
  //         className="col-md-3 col-sm-4 col-lg-2 col-sx-2 col-6"
  //         src={require("../../../Assets/reviews.png")}
  //         alt="reviews"
  //         style={{ cursor: "pointer" }}
  //       />
  //       <span
  //         style={{
  //           display: "block",
  //           width: "90px",
  //           height: "6px",
  //           backgroundColor: "#75abdc",
  //           position: "absolute",
  //           bottom: "-10px",
  //           left: "50%",
  //           transform: "translateX(-50%)",
  //           borderRadius: "0px 0px 5px 5px",
  //           opacity: 0.7,
  //         }}
  //       />
  //     </h2>
  //   </div>

  //   <div style={{ position: "relative" }}>
  //     <Carousel
  //       activeIndex={index}
  //       onSelect={handleSelect}
  //       controls={false} // Hide default controls
  //       indicators={false} // Hide default indicators
  //       interval={3000} // Auto-slide every 3 seconds
  //     >
  //       {reviews.map((review, idx) => (
  //         <Carousel.Item key={idx}>
  //           <div
  //             className="d-flex flex-column align-items-center"
  //             style={{
  //               background: "#ffffff",
  //               borderRadius: "10px",
  //               padding: "20px",
  //               boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  //               width: "90%", // Ensure carousel items take up less width on mobile
  //               maxWidth: "400px", // Limit the width on larger screens
  //             }}
  //           >
  //             <img
  //               src={review.image}
  //               alt={review.name}
  //               className="rounded-circle mb-3"
  //               style={{
  //                 width: "80px",
  //                 height: "80px",
  //                 objectFit: "cover", // Ensures images fit nicely in a circle
  //               }}
  //             />
  //             <p
  //               className="text-center mb-3"
  //               style={{
  //                 fontStyle: "italic",
  //                 fontSize: "16px",
  //                 padding: "10px",
  //                 color: "#333",
  //                 textAlign: "center",
  //               }}
  //             >
  //               "{review.review}"
  //             </p>
  //             <h5>{review.name}</h5>
  //             <p style={{ color: "#ff9900", margin: "10px 0" }}>
  //               {"★".repeat(review.rating)}{" "}
  //               {"☆".repeat(5 - review.rating)}
  //             </p>
  //           </div>
  //         </Carousel.Item>
  //       ))}
  //     </Carousel>

  //     {/* Navigation Buttons */}
  //     <Button
  //       onClick={handlePrev}
  //       style={{
  //         position: "absolute",
  //         top: "50%",
  //         left: "10px",
  //         transform: "translateY(-50%)",
  //         zIndex: 10,
  //         backgroundColor: "transparent",
  //         borderColor: "transparent",
  //       }}
  //     >
  //       <img
  //         src={left_arrow_image}
  //         alt="left-arrow"
  //         style={{
  //           width: "30px",
  //           height: "30px",
  //         }}
  //       />
  //     </Button>
  //     <Button
  //       onClick={handleNext}
  //       style={{
  //         position: "absolute",
  //         top: "50%",
  //         right: "10px",
  //         transform: "translateY(-50%)",
  //         zIndex: 10,
  //         backgroundColor: "transparent",
  //         borderColor: "transparent",
  //       }}
  //     >
  //       <img
  //         src={right_arrow_image}
  //         alt="right-arrow"
  //         style={{
  //           width: "30px",
  //           height: "30px",
  //         }}
  //       />
  //     </Button>
  //   </div>
  // </div>
<div
  style={{
    position: "relative",
    overflow: "hidden",
    width: "100%", // Ensure the container never exceeds the viewport width
  }}
>
<div
  style={{
    textAlign: "center",
    padding: isSmallDevice ? "10px" : "20px",
    marginTop: isSmallDevice ? "15px" : "30px",
    backgroundColor: "#ffff",
    borderRadius: "8px",
  }}
>
  <h2
    style={{
      fontSize: isSmallDevice ? "1.8rem" : isTablet ? "2.5rem" : "3rem",
      fontWeight: "400",
      marginBottom: isSmallDevice ? "20px" : "40px",
      color: "black",
      textAlign: "center",
      position: "relative",
    }}
  >
    <img
      onClick={() => history.push("/customerReviews")}
      className="col-md-3 col-sm-4 col-lg-2 col-sx-2 col-6"
      src={require("../../../Assets/reviews.png")}
      alt="Customer Reviews"
      style={{
        cursor: "pointer",
        width: isSmallDevice ? "120px" : "220px",
        marginTop: "15px",
      }}
    />
    <span
      style={{
        display: "block",
        width: isSmallDevice ? "60px" : "90px",
        height: isSmallDevice ? "4px" : "6px",
        backgroundColor: "#75abdc",
        position: "absolute",
        bottom: isSmallDevice ? "-5px" : "-10px",
        left: "50%",
        transform: "translateX(-50%)",
        borderRadius: "0px 0px 5px 5px",
        opacity: 0.7,
      }}
    />
  </h2>
</div>
  <Carousel
    activeIndex={index}
    onSelect={handleSelect}
    controls={false}
    indicators={false}
    interval={3000}

    style={{backgroundColor: "rgb(255, 255, 255)"}}
  >
    {reviews.map((review, idx) => (
      <Carousel.Item key={idx}>
        <div
          className="d-flex flex-column align-items-center"
          style={{
            background: "#ffffff",
            padding: "20px",
            width: "100%", // Adjust width to be responsive
            maxWidth: isSmallDevice ? "90%" : "70%", // Narrower on small devices
            margin: "0 auto", // Center horizontally
            boxSizing: "border-box", // Include padding in the width calculation
          }}
        >
          <div
            style={{
              position: "relative",
              width: isSmallDevice ? "150px" : "200px",
              height: isSmallDevice ? "150px" : "200px",
              margin: "0 auto",
              borderRadius: "50%",
              padding: "10px",
              background: "radial-gradient(circle, rgb(255 223 135) 61%, rgb(255 239 194) 60%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={review.image}
              alt={review.name}
              style={{
                width: "80%",
                height: "80%",
                objectFit: "cover",
                borderRadius: "50%",
                // marginTop: "15px",
              }}
            />
          </div>

          <p
            className="text-center mb-3"
            style={{
              fontStyle: "italic",
              fontSize: isSmallDevice ? "12px" : "14px",
              padding: isSmallDevice ? "5px" : "10px",
              color: "#333",
              textAlign: "center",
              overflow: "hidden", // Hide overflow text
              textOverflow: "ellipsis",
              whiteSpace: "normal", // Allow wrapping but truncate with fixed height
              maxHeight: "50px", // Adjust this height to control visible text
            }}
          >
            "{review.review}"
          </p>
          <h5 style={{ fontSize: "17px", fontWeight: "700" }}>{review.name}</h5>
          <p style={{ color: "#ff9900", margin: "10px 0" }}>
            {"★".repeat(review.rating)}{" "}
            {"☆".repeat(5 - review.rating)}
          </p>
        </div>
      </Carousel.Item>
    ))}
  </Carousel>

  {/* Updated Buttons */}
  <Button
    onClick={handlePrev}
    style={{
      position: "absolute",
      top: "55%",
      left: "65px",
      transform: "translateY(-50%)",
      zIndex: 10,
      backgroundColor: "transparent",
      border: "none",
      padding: "5px",
    }}
  >
    <img
      src={left_arrow_image}
      alt="left-arrow"
      style={{
        width: "14px",
        height: "28px",
      }}
    />
  </Button>
  <Button
    onClick={handleNext}
    style={{
      position: "absolute",
      top: "55%",
      right: "65px",
      transform: "translateY(-50%)",
      zIndex: 10,
      backgroundColor: "transparent",
      border: "none",
      padding: "5px",
    }}
  >
    <img
      src={right_arrow_image}
      alt="right-arrow"
      style={{
        width: "14px",
        height: "28px",
      }}
    />
  </Button>
</div>


  );
};

// const styles: { [key: string]: React.CSSProperties } = {
//   section: {
//     padding: "40px 20px",
//     color: "#fff",
//     textAlign: "center" as const, // 'as const' ensures the correct type
//   },
//   header: {
//     marginBottom: "40px",
//   },
//   reviewContainer: {
//     display: "flex",
//     overflowX: "auto",
//     padding: "20px 0",
//     scrollSnapType: "x mandatory",
//     WebkitOverflowScrolling: "touch",
//   },
//   reviewCard: {
//     flex: "0 0 350px",
//     background: "#FFFFFF",
//     padding: "20px",
//     borderRadius: "0px",
//     textAlign: "left" as const, // 'as const' ensures the correct type
//     scrollSnapAlign: "start",
//     display: "flex",
//     alignItems: "center",
//   },
//   reviewImage: {
//     borderRadius: "50%",
//     width: 72.9,
//     height: 79,
//     marginRight: "20px",
//   },
//   reviewContent: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "flex-start",
//   },
//   city: {
//     fontSize: "23px",
//     fontWeight: "700",
//     color: "black",
//     margin: "0",
//   },
//   stars: {
//     color: "#75abdc",
//     margin: "10px 0",
//   },
//   reviewText: {
//     fontSize: 16,
//     fontWeight: "400",
//     color: "#333",
//   },
//   reviewerName: {
//     fontSize: 18,
//     color: "black",
//     fontWeight: "500",
//   },
// };

// const styles: { [key: string]: React.CSSProperties } = {
//   section: {
//     padding: "40px 20px",
//     color: "#000",
//     textAlign: "center",
//     background: "#fff",
//     fontFamily: "Arial, sans-serif",
//   },
//   header: {
//     marginBottom: "40px",
//   },
//   title: {
//     fontSize: "2.5rem",
//     fontWeight: "bold",
//     color: "#000",
//     position: "relative",
//     textAlign: "center",
//   },
//   reviewContainer: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: "20px",
//   },
//   reviewCard: {
//     maxWidth: "350px",
//     padding: "20px",
//     background: "#fff",
//     borderRadius: "10px",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//     textAlign: "center",
//     border: "1px solid #f0f0f0",
//   },
//   reviewImage: {
//     width: "80px",
//     height: "80px",
//     borderRadius: "50%",
//     margin: "0 auto",
//     display: "block",
//   },
//   reviewContent: {
//     marginTop: "15px",
//   },
//   reviewText: {
//     fontSize: "14px",
//     fontWeight: "400",
//     color: "#333",
//     lineHeight: "1.6",
//     marginBottom: "10px",
//   },
//   reviewerName: {
//     fontSize: "16px",
//     fontWeight: "600",
//     color: "#000",
//     marginBottom: "5px",
//   },
//   stars: {
//     color: "#f4b400", // Gold color for stars
//     fontSize: "14px",
//   },
// };
// const styles: { [key: string]: React.CSSProperties } = {
//   header: {
//     marginBottom: "20px",
//     textAlign: "center",
//   },
//   carouselItem: {
//     background: "#ffffff",
//     borderRadius: "10px",
//     padding: "30px",
//     boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
//   },
//   responsiveImage: {
//     width: "80px",
//     height: "80px",
//   },
//   responsiveText: {
//     fontSize: "16px",
//   },
//   carouselText: {
//     fontSize: "14px",
//     padding: "20px",
//   },
// };
export default CustomerReviewSection;
