import { HeaderList } from "../header/Header";
export enum axiosType {
  get = "get",
  post = "post",
  put = "put",
  delete = "delete",
}
// 8193043551
export const exploreDescription =
  "Explore stunning destinations, from exotic tropical paradises to historic landmarks and vibrant cityscapes with Lyamii.";
export const mapDescription =
  "It is an invitation to expand the horizons of your existence and witness the remarkable resilience of human spirit. Through travel, we become citizens of the world, the best version of ourselves.";
export const reasons = [
  {
    title: "Global Reach",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1-i4SnWI_3JTAE1GqhHWzg5dNGBH9rtpb", // Replace with your image URLs

    description:
      "Lyamii accommodation covers 55+ countries. 3000+ volunteers are always there to assist you on the go. With Lyamii embrace global citizenship like never before.",
  },
  {
    title: "Complete Tours",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1jhBH7SaBQqMalwI4ircl0BVrBYAygc_3",
    description:
      "The Tour IDs connect all dots of your tour. Domestic flights, stays, currency exchange, transfers, cuisines, entry passes, multilingual support, everything is covered with Lyamii.",
  },
  {
    title: "Secure & Convenient",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1jhcvwatXJnwtvQm_rU_F5Onmst49rani",
    description:
      "Experience secure and seamless transactions on Lyamii. We also accept doorstep and post arrival payments. For our new users we have introduced the Book Now option.",
  },
  {
    title: "Diverse & Dynamic",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1jpE3Rp5AwbiZ0ZbFmIzBgQyaMWY2tJM9",
    description:
      "From mountain to ocean, Lyamii covers all kinds of terrains, connects diverse cultures, and caters various types of tours to different sets of audience, all at one platform.",
  },
];
export const facilities = [
  // require("../../../Assets/bell.png"),
  // require("../../../Assets/car.png"),
  // require("../../../Assets/house.png"),
  // require("../../../Assets/boat.png"),
  // require("../../../Assets/notepad.png"),
  // require("../../../Assets/food.png"),
  // require("../../../Assets/police.png"),
  "Domestic Flights",
  "Lounge Access",
  "Airport drop-pickup",
  "Currency Exchange ",
  "Travel Insurance",
  "Health Insurance",
  "UPI Wallet",
  "Luxury Accommodations",
  "Cuisines",
  "SUV Transfers",
  "Personal Guide",
  "Entry Tickets ",
  "Show Tickets",
  "Activities",
];
export const bussinessClints = [
  require("../../../Assets/indigo.png"),
  require("../../../Assets/tata.png"),
  require("../../../Assets/taj.png"),
  require("../../../Assets/nestle.png"),
  require("../../../Assets/marriott.png"),
  require("../../../Assets/hdfc.png"),
];
export const paths = [
  "/signup",
  "/login",
  "/joinus",
  "/addHotel",
  "/addHostel",
  "/profile",
  "/crm",
];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const teamRating = [5, 4, 5, 4];
export const reviews = [
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yMQ_4-zpbT5mWL_pANgspj3GJ_vhEOfE",
    review:
      "Hospitality at its finest. This is the first impression I give explaining my vacation with Lyamii in India. Met 8 volunteers throughout our journey, and each of them were very very humble and helpful.",
    name: "Jordan",
    rating: 4
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yNaNPud3LrhRPGiKN8I-AAhCanNgCQ5p",
    review:
      "Lo que la gente no entiende es que la India es un país enorme con un montón de culturas e idiomas diferentes. Visité la India por mi cuenta en 2017 y fue un caos. Finalmente visité nuevamente este año y la experiencia general fue muy diferente con Lyamii. Lo pasé muy bien e hice muchos amigos y seguramente volveré pronto.",
    name: "Molina",
    rating: 3
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yR9JtGCoL74F8Ysw_-24S3aQvUWyn49c",
    review:
      "He viajado a 67 países hasta ahora y desearía tener a Lyamii en otros países también. Toda la asistencia fue tan equilibrada que nunca me di cuenta de que estaba solo en un país nuevo.",
    name: "Mark",
    rating: 5
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yTe5baVXapqxh1Sn7faXUB-8kTIW_Zam",
    review:
      "India is definitely a vlogger’s paradise, from food to people, architecture to nature. I loved all of it. And I'm definitely enjoying the love I'm receiving on my recent contents on youtube from my Indian friends. Thank you Lyamii.",
    name: "Martha",
    rating: 4
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yUSIDQKK3R-tfeblZ0sFLUvUq43Lj7Kj",
    review:
      "Samapika, our assigned volunteer in Jaipur and my 6 years old daughter became best friends. She brought a handmade toy for my girl on our last day in Jaipur. It was a pure gem moment for us.",
    name: "Antony",
    rating: 4
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yVDL0wHV0MGOErlO9KRsB8UGWOTjQrjW",
    review:
      " Chaque jour de mon voyage de randonnée de 50 jours était unique à sa manière. Sans Lyamii, je ne pourrais jamais imaginer à quel point ce pays est multiculturel. Et à mes amis bénévoles, j'aurais aimé avoir assez d'argent pour vous accueillir dans ma ville natale. Je plaisante, nous nous reverrons bientôt.",
    name: "Jonathan",
    rating: 3
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yVbgtC5ySSuh1HjggNX1XDbVrLNYJ8Kv",
    review:
      "All the properties we stayed in were very comforting and photogenic. We literally spent most of our days within our boutique hotels and honestly loved every bit of it, the hosts felt like home. The forts we stayed in Rajasthan were top notch.",
    name: "Martin",
    rating: 2
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1ygaqweGEP3XzGOjd_2kHTQrrY03nTxVV",
    review:
      "Las estancias y los traslados fueron buenos, pero la comida fue la mejor parte. Sabores tras sabores.  Nunca imaginamos que pudieran existir alimentos tan sabrosos. Cocinas tipo buffet, delicias sorpresa y paseos por la comida callejera con voluntarios: cada día era una celebración para un entusiasta de la comida como yo.",
    name: "Philips",
    rating: 4
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yi5Vk_eTG_f0rUJQQ-b_6QNgTRpmBKvU",
    review:
      " I loved how all the volunteers in my day to day itinerary refused to take any tip from me, I mean this is very rare in other countries. All of them were very polite and professional.",
    name: "Martin",
    rating: 5
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yiP9PJaQaWU4iE_ILW7J7nsrplyDNVy3",
    review:
      "Received my journal just today, it is beautiful. Although I'm a seasoned traveller, still backpacking for 75 days in such a big country was something I never thought of attempting. My vacation with your platform was so smooth and precious it still makes me wonder how a duration of two months felt not enough.",
    name: "Tayo",
    rating: 4
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yqczsmEilEhVNKQQj-HAdVEHxHy1Bnjt",
    review:
      " I'm from Tbilisi and I had the best day of my life in the streets of varanasi. Met Vandana as my volunteer in Varanasi, she helped me a lot with bargaining. My other volunteer friends were super nice and helpful too.",
    name: "Kelly",
    rating: 5
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yrNRwtFCvheBioNl3uytM5cBt6gSV4Z3",
    review:
      " Grâce à Lyamii, j'ai trouvé l'amour de ma vie dans la cuisine indienne. Jusqu'à présent, ma femme et moi avons pris deux vacances en Inde avec Lyamii en seulement trois mois. Nous ne pensons pas que nous cesserons un jour de revenir dans ce pays.",
    name: "Peter",
    rating: 4
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yuy7Cx1EV_HN9Ok8LvCuexGmJqXtH1Vb",
    review:
      " Just returned from our first 30 days vacation in India. Let me assure you, you will never get bored. Each location in India has its own charm. With every transition the language, food, weather everything becomes new.",
    name: "Bec",
    rating: 4
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yvLpGSBuLuSktxisn76jZSbx-kIV8IPG",
    review:
      "I'm totally amazed by the attention to detail by Lyamii. I did have mixed feelings about the outcome of my vacation as it was my first time in India, however each day was super compact and my volunteers were very helpful in order to guide me from places to places.",
    name: "Mark",
    rating: 5
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yx-RoDpdpR8cKxonOPIcYGVToJfXhOZp",
    review:
      " Calcuta fue la primera ciudad de nuestra gira y todavía podía sentir los ritmos que descubrí allí. Fue muy relajante pero muy colorido. Nuestro amigo guía Aditi respondió todas mis preguntas estúpidas e incluso me ayudó a elegir mi primer saari..",
    name: "Eva",
    rating: 3
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1z1_SYoHYd-fCjLoFPOOjkdJCzOSy_b9Y",
    review:
      "Loved the journal. Thankyou for all the good memories and wonderful new friends. Heartfully willing to meet all my hosts again. Each of them showered me with some kind of kindness that I never experienced before. ",
    name: "Lexi",
    rating: 4
  },
  {
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1z1a4OQVxyBoX6nBjDpT9e8BsJEWfu3U2",
    review:
      "Me encanta viajar a nuevos países, encontré un cartel de lyamii en la pared de mi albergue en Barcelona. Me impresionó pero, sinceramente, no estoy totalmente seguro de mis primeras vacaciones contigo.  Pero desde la primera llamada hasta el último día de mi gira de 12 días, la experiencia general fue muy reconfortante.",
    name: "John",
    rating: 4
  },
  {
    name: "Jasmine",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1z3HGNjKPdXXC460hlmzb_S4VzKLz0AZp",
    review:
      "Always wanted to visit India someday, but could never gather enough information about the country. Just last year I got to know about this platform. My brother and I took our first trip to India with lyamii. The flow of the tour was pretty nice, we discovered 4 different cultures in our 7 days tour.",
    rating: 4,
  },
  {
    rating: 4,
    name: "Alfred",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1z4JYN3J5VqO0lem9T1RYOJplGLbMDqeF",
    review:
      "Adorei nossas férias na Índia. Foram definitivamente nossos melhores 10 dias em um país estrangeiro. Belas estadias, autêntica cozinha indiana e pessoas de coração aberto em todos os lugares.  Graças aos nossos amigos voluntários, não enfrentamos nenhuma barreira de comunicação ao longo de nossa jornada.",
  },
  {
    rating: 4,
    name: "Mauro",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1yn-eth8ZrDs3kJ9A3hGRwYtPcjlQ4O-p",
    review:
      "Varanasi fue nuestro favorito, esos dos días fueron tan poderosos y relajantes que nunca habíamos experimentado tal vibra en una ciudad.  Cada transición entre diferentes culturas fue de primera categoría..",
  },
  {
    rating: 4,
    name: "Vavá",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1z4vVSpz9TiK52d_ZUUC9-rnP2tG7MELr",
    review:
      "India is so precious, I mean we covered 6 cities and all of them had something special, something different to offer. The love we received from the local people will always stay with us, looking forward to come back with our families next winter.",
  },
  {
    rating: 4,
    name: "Haynes",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1z5E6HJo9Oww_ZTcwQJIvnmTQQru_0pSP",
    review:
      "Quiero agradecer a lyamii por la asistencia diaria que recibí durante mi estadía de 30 días en la India. Era la cuarta vez que visitaba su país. Mis primeros tres viajes parecieron muy divertidos cuando los comparé con mi última gira.",
  },
  {
    rating: 4,
    name: "Lawton",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1zBvXO3zX38m1AHN09_qvtdRyIJo-jasj",
    review:
      "I've been travelling around for quite a while now. The best thing about my trip with this platform was the transition from one culture to another. The diversity of India is truly very very unimaginable. Thank You lyamii for the experience.",
  },
  {
    rating: 4,
    name: "Ramsey",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1zDr5POwFEOwVEkzmYdJwrdBdOvlSEXan",
    review:
      "We loved our vacation, from each castle stay to all our day to day volunteers, the buffet meals, everything was well balanced and I don't think they left any margin for the guests to complain about.",
  },
  {
    rating: 4,
    name: "Ella",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1zLze2xBYe5WXB7sjcaSYkEWoGYBFA3qu",
    review:
      "The vacation felt like a movie, from day one to day ten, everything was very professionally connected. The volunteer lady who helped me with the street foods in Kashmir was very humble, she denied any tip yet took an extra hour to see me off at the airport. ",
  },
  {
    rating: 4,
    name: "Leni",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1zM8FlAHytImyIbvvnNH2q4gHsMQRey8x",
    review:
      "Grâce à Lyamii, j'ai désormais plus d'un million de souvenirs heureux à vivre. Chaque ville de mes vacances en Inde était parfaite différemment. Mes amis bénévoles, merci les gars, tous mes meilleurs vœux pour vous tous, nous nous reverrons certainement.",
  },
  {
    rating: 4,
    name: "Sophie",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1zQNSKQpyZbpY64w7390bvLsgRYl_48rk",
    review:
      "La communication était le principal problème lorsque je prévoyais de visiter l'Inde.  Ce pays compte plus de 25 langues.  Merci d'avoir résolu ce problème pour moi, j'apprécie totalement les efforts de mes bénévoles, un grand soutien.",
  },
  {
    rating: 4,
    name: "Mia",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1zS-cE9GbcjitjbKm-hoXpOxl9uzWt9VH",
    review:
      "Got to know about this platform from a friend of mine, never thought my India tour would be this easy and exciting. Loved everything about the vacation. Will visit soon with more friends.",
  },
  {
    rating: 4,
    name: "Máspoli",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1zSJTJwxW0aUricMoLCE_Eo7f2dkNDaJc",
    review:
      "Had the best foods, best people and great locations throughout my 30 days backpacking vacation across India. I don't think any other country could have provided such diversity within a single vacation.",
  },
  {
    rating: 4,
    name: "Sarah",
    image:
      "https://drive.google.com/thumbnail?sz=w2000&id=1zXrUN7N8E7smbEtHwbE6VuRvnmuO6syB",
    review:
      "I had an amazing experience booking my vacation through this website. The customer support team was incredibly helpful in answering all my questions and providing recommendations. Our trip went off without a hitch, and I can't wait to use Lyamii again for my next adventure!",
  },
  {
    rating: 4,
    name: "John D",
    image: "",
    review:
      "Overall, a great travel booking website! I found some fantastic deals and discounts on flights and hotels for my recent solo trip. The search filters were handy for narrowing down my options, and the booking process was smooth. The only reason I'm not giving it 5 stars is that I encountered a minor issue with my hotel reservation, but the customer support team promptly resolved it. I'd definitely use this website again for my next getaway.",
  },
  {
    rating: 4,
    name: "Billy Bremner",
    image: "",
    review:
      "This website has a wide range of travel options, but my experience was a bit mixed. I found a great deal on a tour package to a beautiful destination, but the booking process felt a bit clunky, and I had some trouble with payment processing. Eventually, I managed to secure my reservation with the help of customer support, but it was a bit frustrating. On the positive side, the tour itself was fantastic, and the local guides were knowledgeable and friendly.",
  },      
  
];

export const communities: HeaderList[] = [
  { name: "About us", path: "/aboutus" },
  { name: "Our Mission", path: "/mission" },
  { name: "Join us", path: "/joinus" },
];
export const mustReads: HeaderList[] = [
  { name: "Terms and conditions", path: "/termconditions" },
  { name: "Privacy policy", path: "/privacypolicy" },
  {
    name: "Cancellation and Refund policy",
    path: "/cancellationandRefundpolicy",
  },
  { name: "User Manual", path: "/userManual" },
  { name: "FAQ", path: "/faqs" },
];
export const supports: HeaderList[] = [
  { name: "My Profile", path: "/login" },
  { name: "Helpdesk", path: "/contact" },
  { name: "Feedback", path: "/customerReviews" },
];
export const journeyOptions = [
  {
    name: "ROYAL BHARAT EDITION",
    imgPath: require("../../../Assets/bharat.png"),
    path: "/bharat-tours",
  },
  {
    name: "BACKPACKERS EDITION",
    imgPath: require("../../../Assets/backpackers.jpeg"),
    path: "/backpackers",
  },
  {
    name: "COMBOS",
    imgPath: require("../../../Assets/combo.jpeg"),
    path: "/combo",
  },
];
export const manuals = [
  {
    name: "English",
    imgPath: require("../../../Assets/english.png"),
    path: "https://drive.google.com/thumbnail?sz=w2000&id=1tIkqcnDA3Jf8I7Fns6HtchTUi6OJfauO",
  },
  {
    name: "French",
    imgPath: require("../../../Assets/french.png"),
    path: "https://drive.google.com/thumbnail?sz=w2000&id=1tKKq-Wj4SjTLkSfpUw66h-du7Ep2nUqF",
  },
  {
    name: "Portugues",
    imgPath: require("../../../Assets/portugues.png"),
    path: "https://drive.google.com/thumbnail?sz=w2000&id=1tn4wVlaXJvghzxWQ9NGORx_KqS2A9RAT",
  },
  {
    name: "Arabic",
    imgPath: require("../../../Assets/arabic.png"),
    path: "https://drive.google.com/thumbnail?sz=w2000&id=1tUyIx9mEu14ASB7P88sY4jpLO_P5antT",
  },
  {
    name: "Russia",
    imgPath: require("../../../Assets/russian.png"),
    path: "https://drive.google.com/thumbnail?sz=w2000&id=1tKrozZRyGIHQ7Mm2qElaTk-HiMS4qfLF",
  },
  {
    name: "Spanish",
    imgPath: require("../../../Assets/spanish.png"),
    path: "https://drive.google.com/thumbnail?sz=w2000&id=1tkansuyZKHrWtqTZxVrI74SP0fdf77bB",
  },
];
export const internationalTours = [
  {
    from: "IND",
    to: "Egypt",
    path: require("../../../Assets/header.jpg"),
  },
  {
    from: "IND",
    to: "Japan",
    path: require("../../../Assets/header1.jpeg"),
  },
  {
    from: "IND",
    to: "Turkey",
    path: require("../../../Assets/header2.jpeg"),
  },
  {
    from: "IND",
    to: "Cambodia",
    path: require("../../../Assets/header.jpg"),
  },
  {
    from: "IND",
    to: "United Kingdom",
    path: require("../../../Assets/header2.jpeg"),
  },
  {
    from: "IND",
    to: "Iceland",
    path: "",
  },
  {
    from: "IND",
    to: "Norway",
    path: "",
  },
  {
    from: "IND",
    to: "Hungary",
    path: "",
  },
  {
    from: "IND",
    to: "Montenegro",
    path: "",
  },
  {
    from: "IND",
    to: "Albania",
    path: "",
  },
  {
    from: "IND",
    to: "Romania",
    path: "",
  },
  {
    from: "IND",
    to: "South Korea",
    path: "",
  },
  {
    from: "IND",
    to: "Cyprus ",
    path: "",
  },
  {
    from: "IND",
    to: "Malta",
    path: "",
  },
  {
    from: "IND",
    to: "Belgium",
    path: "",
  },
  {
    from: "IND",
    to: "Croatia",
    path: "",
  },
  {
    from: "IND",
    to: "Uzbekistan",
    path: "",
  },
  {
    from: "IND",
    to: "Russia",
    path: "",
  },
  {
    from: "IND",
    to: "Bolivia",
    path: "",
  },
  {
    from: "IND",
    to: "Ireland",
    path: "",
  },
  {
    from: "IND",
    to: "Baltic States",
    path: "",
  },
  {
    from: "IND",
    to: "Sri Lanka",
    path: "",
  },
  {
    from: "IND",
    to: "Vietnam",
    path: "",
  },
  {
    from: "IND",
    to: "Thailand",
    path: "",
  },
  {
    from: "IND",
    to: "Indonesia ",
    path: "",
  },
];
export const bharatTours = [
  {
    from: "kolkatal",
    to: "delhi",
    days: "15",
    priceInUSD: "12,000 USD",
    priceInEUR: "11,500 EUR",
    priceInAED: "45,000 AED",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Kolkatal",
      "Kolkata",
      "Hyderabad",
      "Hyderabad",
      "Kerala Backwaters",
      "Kerala Backwaters",
      "Mumbai",
      "Mumbai",
      "Udaipur",
      "Jodhpur",
      "Jodhpur",
      "Jaipur",
      "Jaipur",
      "Lucknow",
      "Agra",
      "Delhi",
      "Delhi",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    days: "20",
    priceInUSD: "15,000 USD",
    priceInEUR: "14,500 EUR",
    priceInAED: "55,000 AED",
    img: require("../../../Assets/header2.jpeg"),
    places: [
      "Kolkata",
      "Andaman Nicobar",
      "Andaman Nicobar",
      "Kolkata",
      "Varanasi",
      "Varanasi",
      "Hyderabad",
      "Hyderabad",
      "Kerala Backwaters",
      "Kerala Backwaters",
      "Mumbai",
      "Mumbai",
      "Vadodara",
      "Statue of Unity",
      "Jaipur",
      "Jaipur",
      "Bharatpur",
      "Agra",
      "Delhi",
      "Kashmir",
      "Kashmir",
      "Kashmir",
      "Delhi",
      "Delhi",
    ],
  },
  {
    from: "kolkatal",
    to: "delhi",
    days: "30",
    priceInUSD: "18,000 USD",
    priceInEUR: "16,000 EUR",
    priceInAED: "65,000 AED",
    img: require("../../../Assets/header1.jpeg"),
    places: [
      "Kolkata",
      "Andaman Nicobar",
      "Andaman Nicobar",
      "Andaman Nicobar",
      "Kolkata",
      "Varanasi",
      "Varanasi",
      "Varanasi",
      "Hyderabad",
      "Hyderabad",
      "Kerala Backwaters",
      "Kerala Backwaters",
      "Mumbai",
      "Mumbai",
      "Vadodara",
      "Statue of Unity",
      "Jaipur",
      "Jaipur",
      "Jaipur",
      "Delhi",
      "Agra",
      "Kashmir",
      "Kashmir",
      "Kashmir",
      "Leh",
      "Nubra Valley",
      "Nubra Valley",
      "Pangong Lake",
      "Leh",
      "Amritsar",
      "Amritsar",
      "Amritsar",
      "Delhi",
      "Delhi",
    ],
  },
  {
    from: "kolkatal",
    to: "delhi",
    days: "50",
    priceInUSD: "22,000 USD",
    priceInEUR: "20,500 EUR",
    priceInAED: "80,000 AED",
    img: require("../../../Assets/header.jpg"),
    places: [
      "Kolkata",
      "Kolkata",
      "Sundarbans",
      "Sundarbans",
      "Kolkata",
      "Andaman Nicobar",
      "Andaman Nicobar",
      "Andaman Nicobar",
      "Kolkata-Shillong",
      "Meghalaya",
      "Meghalaya",
      "Delhi",
      "Agra",
      "Lucknow",
      "Varanasi",
      "Varanasi",
      "Delhi",
      "Sreenagar",
      "Sreenagar",
      "Pahalgam",
      "Sonamarg",
      "Sonamarg",
      "Leh",
      "Leh",
      "Nubra Valley",
      "Pangong- Leh",
      "Chandigarh",
      "Chandigarh",
      "Patiala",
      "Delhi",
      "Bikaner",
      "Bikaner",
      "Jaisalmer",
      "Jodhpur",
      "Udaipur",
      "Jaipur",
      "Jaipur",
      "VadJodhpurodara",
      "Vadodara",
      "Mumbai",
      "Mumbai",
      "Mumbai - Hyderabad",
      "Hyderabad",
      "Cochin",
      "Varkala/Mararikulam",
      "Kanyakumari",
      "Alleppey",
      "TN- Traditional Stay ",
      "TN- Traditional Stay ",
      "Bengaluru",
      "Bengaluru",
    ],
  },
];
export const backpackersTours = [
  {
    from: "kolkatal",
    to: "Bengaluru",
    days: "20",
    tourType: "Vagabond's Wishlist",
    priceInUSD: "1,70,000 USD",
    priceInEUR: "1,50,000 EUR",
    priceInAED: "",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Kolkata",
      "Kolkata",
      "Sundarbans",
      "Sundarbans",
      "Kolkata",
      "Shillong",
      "Meghalaya",
      "Delhi",
      "Agra",
      "Lucknow",
      "Varanasi",
      "Varanasi",
      "Delhi",
      "Amritsar",
      "Amritsar",
      "Jaipur",
      "Jaipur",
      "Udaipur",
      "Mumbai",
      "Mumbai",
      "Goa",
      "Goa",
      "Humpi",
      "Hyderabad",
      "Hyderabad",
      "Kerala",
      "Kerala",
      "TN- Traditional Stay ",
      "TN- Traditional Stay ",
      "Mysore",
      "Bengaluru",
      "Bengaluru",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    days: "50",
    priceInUSD: "5,20,000 USD",
    priceInEUR: "",
    tourType: "Vagabond's Wishlist",
    priceInAED: "",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Kolkata",
      "Kolkata",
      "Sundarbans",
      "Sundarbans",
      "Kolkata",
      "Kolkata",
      "Shillong",
      "Meghalaya",
      "Meghalaya-Kolkata",
      "Andaman Nicobar",
      "Andaman Nicobar",
      "Andaman Nicobar",
      "Chennai",
      "TN",
      "TN",
      "TN",
      "Kerala",
      "Kerala",
      "Cochin",
      "Mysore",
      "Bengaluru",
      "Bengaluru",
      "Goa",
      "Goa",
      "Goa",
      "Mumbai",
      "Mumbai",
      "Mumbai",
      "Udaipur",
      "Udaipur",
      "Pushkar",
      "Jaipur",
      "Jaipur",
      "Amritsar",
      "Amritsar",
      "Delhi",
      "Agra",
      "Lucknow",
      "Lucknow",
      "Varanasi",
      "Varanasi",
      "Varanasi",
      "Delhi",
      "Delhi",
      "Srinagar",
      "Srinagar",
      "Kashmir",
      "Kashmir",
      "Kashmir",
      "Leh",
      "Leh",
      "Nubra Valley",
      "Leh-Manali",
      "Manali",
      "Kasol",
      "Kasol",
      "Shimla",
      "Shimla",
      "Chandigarh",
      "Chandigarh",
      "Haridwar",
      "Rishikesh",
      "Rishikesh",
      "Delhi",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    tourType: "Vagabond's Wishlist",
    days: "75",
    priceInUSD: "18,000 USD",
    priceInEUR: "16,000 EUR",
    priceInAED: "65,000 AED",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Delhi",
      "Delhi",
      "Delhi",
      "Jaipur",
      "Udaipur",
      "Jaisalmer",
      "Bikaner",
      "Ajmer",
      "Gujrat",
      "Gujrat",
      "Gujrat",
      "Gujrat",
      "MP",
      "MP",
      "Maharashtra",
      "Maharashtra",
      "Maharashtra",
      "Maharashtra",
      "Goa",
      "Goa",
      "Goa",
      "Karnataka",
      "Karnataka",
      "Karnataka",
      "Karnataka",
      "Tamilnadu",
      "Tamilnadu",
      "Tamilnadu",
      "Tamilnadu",
      "Tamilnadu",
      "Kerala",
      "Kerala",
      "Kerala",
      "Kerala",
      "Hyderabad",
      "Hyderabad",
      "Kolkata",
      "Kolkata",
      "Andaman",
      "Andaman",
      "Andaman",
      "Kolkata",
      "Sundarbans",
      "Sundarbans",
      "Sundarbans",
      "North East",
      "North East",
      "North East",
      "North East",
      "North East",
      "North East",
      "North East",
      "Sikkim",
      "Sikkim",
      "Bihar",
      "Bihar",
      "Uttar Pradesh",
      "Uttar Pradesh",
      "Uttar Pradesh",
      "Uttar Pradesh",
      "Uttar Pradesh",
      "Jammu Kashmir",
      "Jammu Kashmir",
      "Ladakh",
      "Ladakh",
      "Ladakh",
      "Ladakh",
      "Ladakh",
      "Himachal",
      "Himachal",
      "Himachal",
      "Himachal",
      "Chandigarh",
      "Chandigarh",
      "Punjab",
      "Punjab",
      "Haryana",
      "Uttarakhand",
      "Uttarakhand",
      "Uttarakhand",
      "Uttarakhand",
      "Delhi",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    tourType: "Spiritual Enlightenment",
    days: "50",
    priceInUSD: "22,000 USD",
    priceInEUR: "20,500 EUR",
    priceInAED: "80,000 AED",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Auroville",
      "Pondicherry",
      "Rishikesh",
      "Haridwar",
      "Lucknow",
      "Varanasi",
      "Pushkar",
      "Ajmer",
      "Mathura",
      "Vrindavan",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    tourType: "Spiritual Enlightenment",
    days: "50",
    priceInUSD: "22,000 USD",
    priceInEUR: "20,500 EUR",
    priceInAED: "80,000 AED",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Auroville",
      "Pondicherry",
      "Rishikesh",
      "Haridwar",
      "Lucknow",
      "Varanasi",
      "Pushkar",
      "Ajmer",
      "Mathura",
      "Vrindavan",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    tourType: "Spiritual Enlightenment",
    days: "50",
    priceInUSD: "22,000 USD",
    priceInEUR: "20,500 EUR",
    priceInAED: "80,000 AED",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Auroville",
      "Pondicherry",
      "Rishikesh",
      "Haridwar",
      "Lucknow",
      "Varanasi",
      "Pushkar",
      "Ajmer",
      "Mathura",
      "Vrindavan",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    tourType: "Spiritual Enlightenment",
    days: "50",
    priceInUSD: "22,000 USD",
    priceInEUR: "20,500 EUR",
    priceInAED: "80,000 AED",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Auroville",
      "Pondicherry",
      "Rishikesh",
      "Haridwar",
      "Lucknow",
      "Varanasi",
      "Pushkar",
      "Ajmer",
      "Mathura",
      "Vrindavan",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    tourType: "Colours of Festivals",
    days: "50",
    priceInUSD: "22,000 USD",
    priceInEUR: "20,500 EUR",
    priceInAED: "80,000 AED",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Ganga Dussehra",
      "Hornbill Festival",
      "Onam",
      "Durga Puja",
      "Diwali",
      "Holi",
      "Theyyam",
      "Garba- Navaratri",
      "Losar",
      "Dasara",
      "Ganesh Chaturthi",
      "Janmashtami",
      "Eid-ul-Fitr",
      "Christmas",
      "Baisakhi",
      "Buddha Purnima",
      "Pongal",
      "Bihu",
      "Makar Sankranti",
      "Hemis",
      "Saga Dawa",
      "Mahavir Jayanti",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    tourType: "Wild Encounter",
    days: "50",
    priceInUSD: "22,000 USD",
    priceInEUR: "20,500 EUR",
    priceInAED: "80,000 AED",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Tigers",
      "The Royal Bengal Tiger",
      "Maharajas of Maharashtra",
      "Jim Corbett",
      "Roars of Ranthambore",
      "Gir NP Madhya Pradesh Majestics",
      "Asiatic Lions",
      "Gir National Park",
      "Ranthambore",
      "Elephant Kerala",
      "Snow Leopards Spiti Valley",
      "Red Panda",
      "Singalila National Park",
      "Fishing Cat West Bengal",
      "Birds",
      "Butterflies",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    tourType: "Sandy Escape",
    days: "50",
    priceInUSD: "22,000 USD",
    priceInEUR: "20,500 EUR",
    priceInAED: "80,000 AED",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Mumbai",
      "Mumbai",
      "Goa",
      "Goa",
      "Goa",
      "Kerala",
      "Kerala",
      "Lakshadweep",
      "Lakshadweep",
      "Lakshadweep",
      "Kerala",
      "Kerala",
      "Tamilnadu",
      "Tamilnadu",
      "Tamilnadu",
      "Tamilnadu",
      "Andhra",
      "Andhra",
      "Odisha",
      "Odisha",
      "Odisha",
      "West Bengal",
      "West Bengal",
      "Andaman",
      "Andaman",
      "Andaman",
      "Andaman",
      "Kolkata",
      "Mumbai",
      "Mumbai",
    ],
  },
  {
    from: "kolkata",
    to: "delhi",
    tourType: "Seven Sisters",
    days: "50",
    priceInUSD: "22,000 USD",
    priceInEUR: "20,500 EUR",
    priceInAED: "80,000 AED",
    img: require("../../../Assets/header.jpeg"),
    places: [
      "Guwahati",
      "Tawang",
      "Tawang",
      "Bomdila",
      "Kaziranga NP",
      "Kaziranga",
      "Shivasagar",
      "Mon town",
      "Mon town",
      "Dzukou Valley",
      "Dzukou Valley",
      "Kohima",
      "Imphal",
      "Loktak Lake",
      "Agartala",
      "Agartala",
      "Aizawl",
      "Aizawl",
      "Shillong",
      "Shillong",
      "Shillong",
      "Guwahati",
      "Guwahati",
      "Guwahati",
    ],
  },
];
//   3. 'COMBOS'
//   1.1. South Indian Combo
//   (Kerala, Karnataka, Tamil Nadu) + Pondicherry
//   Karnataka
//   'Bengaluru
//   'Mysore 'Coorg 'Hampi 'Badami//    'Kabini
//   'Jog falls
//   'Mangalore
//   'Karwar
//   'Gokarna
//   'Udupi
//   'Murudeshwar
//   'Kudremukh
//   Kerala
//   'Kochi
//   'Munnar
//   'Alappuzha
//   'Thiruvananthapuram
//   'Wayanad
//   'Kovalam
//   'Kozhikode
//   'Periyar National Park
//   'Kumarakom
//   'Varkala
//   'Eravikulam National Park
//   'Kollam
//   'Thrissur
//   'Palakkad
//   'Idukki
//   'Marari Beach ●
//   2. The Ganga.
//   (WB, Bihar, UP, UK)
//   3. The North Combo.
//   (Uttarakhand, Himachal, Leh, Kashmir)//   4. West Coast Combo.
//   (Kerala, Goa, Maharashtra, Gujrat)
// ];
export const amenities = [
  { checked: false, label: "Local Tour", value: "LOCAL_TOUR" },
  { checked: false, label: "BreakFast Buffet", value: "BREAKFAST_BUFFET" },
  { checked: false, label: "Wifi", value: "WIFI" },
  { checked: false, label: "Airport Transfer", value: "AIRPORT_TRANSFER" },
  { checked: false, label: "Dinner Buffet", value: "DINNER_BUFFET" },
  { checked: false, label: "Pool", value: "POOL" },
  { checked: false, label: "Gym", value: "GYM" },
];
export const hostelAmenities = [
  { checked: false, label: "Local Tour", value: "LOCAL_TOUR" },
  { checked: false, label: "BreakFast Buffet", value: "BREAKFAST_BUFFET" },
  { checked: false, label: "Female Dorm", value: "FEMALE_DORM" },
  { checked: false, label: "Locker", value: "LOCKER" },
  { checked: false, label: "No Racism", value: "NO_RACISM" },
  { checked: false, label: "Wifi", value: "WIFI" },
  { checked: false, label: "Airport Transfer", value: "AIRPORT_TRANSFER" },
];
export const filterList = [
  // {
  //   filterName: "Type",
  //   subFilter: [
  //     { checked: false, label: "Deluxe" },
  //     { checked: false, label: "Backpacker" },
  //     { checked: false, label: "Standard" },
  //     { checked: false, label: "Indian Passport Holders" },
  //     { checked: false, label: "India Tours" },
  //   ],
  // },
  // {
  //   filterName: "Continents",
  //   subFilter: [
  //     { checked: false, label: "Asia" },
  //     { checked: false, label: " Africa" },
  //     { checked: false, label: " North America" },
  //     { checked: false, label: " South America" },
  //     { checked: false, label: " Antarctica" },
  //     { checked: false, label: " Europe" },
  //     { checked: false, label: " Australia" },
  //   ],
  // },
  {
    filterName: "Days",
    subFilter: [
      {
        checked: false,
        label: "less than 5",
      },
      {
        checked: false,
        label: "5 - 15",
      },
      {
        checked: false,
        label: "15-25",
      },
      {
        checked: false,
        label: "25-45",
      },
      {
        checked: false,
        label: "45-75",
      },
      {
        checked: false,
        label: "more than 75",
      },
    ],
  },
];
export const tourReviews = [
  "Marvel at the grandeur of Prague Castle, one of the largest ancient castles in the world.",
  "Walk along the iconic Charles Bridge, adorned with statues and offering picturesque views of the Vitava River. Experience its lively atmosphere with street artists, musicians, and local vendors.",
  "From Gothic masterpieces like the Powder Tower to the Art Nouveau elegance of the Municipal House, Prague is a treasure trove of architectural gems.",
  "Escape the hustle and bustle of the city and relax in Prague's beautiful gardens. Visit the romantic Vrtba Garden or the tranquil Wallenstein Garden.",
  "Delight your taste buds with Czech cuisine. Try goulash, svíčková, and trdelnik. Don't forget to pair them with a glass of famous Czech beer.",
];
export const isoCountries = [
  {
    id: 1,
    country_name: "India",
    display_code: 91,
  },
  {
    id: 2,
    country_name: "Srilanka",
    display_code: 1,
  },
  {
    id: 3,
    country_name: "Bangladesh",
    display_code: 3,
  },
  {
    id: 4,
    country_name: "Nepal",
    display_code: 4,
  },
  {
    id: 5,
    country_name: "Bhutan",
    display_code: 5,
  },
  {
    id: 6,
    country_name: "Thailand",
    display_code: 6,
  },
  {
    id: 7,
    country_name: "Turkey",
    display_code: 7,
  },
  {
    id: 8,
    country_name: "Vietnam",
    display_code: 8,
  },
  {
    id: 9,
    country_name: "Kuwait",
    display_code: 9,
  },
  {
    id: 10,
    country_name: "Laos",
    display_code: 10,
  },
  {
    id: 11,
    country_name: "Cambodia",
    display_code: 11,
  },
  {
    id: 12,
    country_name: "Indonesia",
    display_code: 12,
  },
  {
    id: 13,
    country_name: "Philippines",
    display_code: 13,
  },
  {
    id: 14,
    country_name: "China",
    display_code: 14,
  },
  {
    id: 15,
    country_name: "Malaysia",
    display_code: 15,
  },
  {
    id: 16,
    country_name: "Japan",
    display_code: 16,
  },
  {
    id: 17,
    country_name: "South Korea",
    display_code: 17,
  },
  {
    id: 18,
    country_name: "Kazakhstan",
    display_code: 18,
  },
  {
    id: 19,
    country_name: "Uzbekistan",
    display_code: 19,
  },
  {
    id: 20,
    country_name: "Russia",
    display_code: 20,
  },
  {
    id: 21,
    country_name: "Mongolia",
    display_code: 21,
  },
  {
    id: 22,
    country_name: "United Arab Emirates",
    display_code: 22,
  },
  {
    id: 23,
    country_name: "Portugal",
    display_code: 23,
  },
  {
    id: 24,
    country_name: "Spain",
    display_code: 24,
  },
  {
    id: 25,
    country_name: "France",
    display_code: 25,
  },
  {
    id: 26,
    country_name: "Italy",
    display_code: 26,
  },
  {
    id: 27,
    country_name: "Germany",
    display_code: 27,
  },
  {
    id: 28,
    country_name: "Netherlands",
    display_code: 28,
  },
  {
    id: 29,
    country_name: "Belgium",
    display_code: 29,
  },
  {
    id: 30,
    country_name: "Czech Republic",
    display_code: 30,
  },
  {
    id: 31,
    country_name: "Croatia",
    display_code: 31,
  },
  {
    id: 32,
    country_name: "Denmark",
    display_code: 32,
  },
  {
    id: 33,
    country_name: "United Kingdom",
    display_code: 33,
  },
  {
    id: 34,
    country_name: "Ireland",
    display_code: 34,
  },
  {
    id: 35,
    country_name: "Austria",
    display_code: 35,
  },
  {
    id: 36,
    country_name: "Greece",
    display_code: 36,
  },
  {
    id: 37,
    country_name: "Malta",
    display_code: 37,
  },
  {
    id: 38,
    country_name: "Cyprus",
    display_code: 38,
  },
  {
    id: 39,
    country_name: "Iceland",
    display_code: 39,
  },
  {
    id: 40,
    country_name: "Norway",
    display_code: 40,
  },
  {
    id: 41,
    country_name: "Georgia",
    display_code: 41,
  },
  {
    id: 42,
    country_name: "Hungary",
    display_code: 42,
  },
  {
    id: 43,
    country_name: "Romania",
    display_code: 43,
  },
  {
    id: 44,
    country_name: "Baltic States",
    display_code: 44,
  },
  {
    id: 45,
    country_name: "Albania",
    display_code: 45,
  },
  {
    id: 46,
    country_name: "Montenegro",
    display_code: 46,
  },
  {
    id: 47,
    country_name: "Bolivia",
    display_code: 47,
  },
  {
    id: 48,
    country_name: "Egypt",
    display_code: 48,
  },
  {
    id: 49,
    country_name: "Namibia",
    display_code: 49,
  },
  {
    id: 50,
    country_name: "Tanzania",
    display_code: 50,
  },
  {
    id: 51,
    country_name: "South Africa",
    display_code: 51,
  },
  {
    id: 52,
    country_name: "Jordan",
    display_code: 52,
  },
  {
    id: 53,
    country_name: "Maldives",
    display_code: 53,
  },
  {
    id: 54,
    country_name: "Australia",
    display_code: 54,
  },
  {
    id: 55,
    country_name: "New Zealand",
    display_code: 55,
  },
  {
    id: 56,
    country_name: "Antarctica",
    display_code: 56,
  },
];
export const tourScheduleDetail = [
  {
    shift: "Morning",
    schedule: [
      {
        event: "Breakfast",
        eventDescription:
          "Start your day with a delicious Goan breakfast. You can try Poi (Goan bread) with Prawn Balchão or Goan Sausage, along with a refreshing glass of Feni (a local liquor made from cashews or coconuts).",
      },
      {
        event: "Carnival",
        eventDescription:
          "Panaji, the capital of Goa. It's the central location for most carnival events.",
      },
      {
        event: "Shantadurga Temple",
        navigator: "/",
        eventDescription:
          "Before the carnival festivities kick off, take a moment to visit the Shantadurga Temple in Kavlem, just a short distance from Panaji. This beautiful temple is an excellent way to experience Goa's spiritual side.",
      },
    ],
  },
  {
    shift: "Afternoon",
    schedule: [
      {
        event: "Enjoy Street Food",
        eventDescription:
          "Grab a quick lunch at one of the many street food stalls in Panaji or nearby areas. You can try some Goan favorites like Goan Fish Curry, Bebinca (traditional Goan dessert), or Vada Pav (a popular Indian street food).",
      },
      {
        event: " Explore the Carnival",
        eventDescription:
          "Head back to Panaji to immerse yourself in the vibrant carnival celebrations. The streets will be filled with music, dancing, colorful floats, and enthusiastic locals dressed in vibrant costumes.",
      },
      {
        event: " Carnival Parade",
        eventDescription:
          "Don't miss the main Carnival Parade that usually starts in the afternoon. It's a grand procession with floats, dancers, live bands, and lots of cheer. Follow the parade route and enjoy the lively atmosphere.",
      },
    ],
  },
  {
    shift: "Evening",
    schedule: [
      {
        event: "Sunset at Miramar Beach",
        navigator: "/",
        eventDescription:
          "After the parade, make your way to Miramar Beach to witness a breathtaking Goan sunset. Relax by the sea, feel the cool breeze, and take in the serene ambiance.",
      },
      {
        event: "Dinner at a Beach Shack",
        navigator: "/",
        eventDescription:
          "For dinner, head to one of the beach shacks or waterfront restaurants to enjoy some fresh seafood and other Goan delicacies. Calangute, Baga, or Anjuna beaches are popular areas with a wide range of dining options.",
      },
    ],
  },
  {
    shift: "Night",
    schedule: [
      {
        event: "Party at a Carnival Event",
        navigator: "/",
        eventDescription:
          "Since it's carnival time, several parties and events will be happening across the city. Choose one of the Carnival-themed events, dance the night away, and enjoy the lively atmosphere with locals and other tourists.",
      },
      {
        event: "Return to your Accommodation",
        navigator: "/",
        eventDescription:
          "After a fun-filled day, travel back to your accommodation. You can use a taxi or rideshare service to ensure a safe trip back.",
      },
    ],
  },
];
