import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectOtpVerification } from '../../../state/selectors/selectLoginData';
import {
  OTP_VERIFICATION,
  SET_LOGIN_DATA,
} from '../../../state/actions/types/loginDataActionType';
import { setCookie } from '../enum/functions';
import { otpVerification } from '../../../api/otpVerification/otpVerfication';
import { getOtpApi } from '../../../api/sentOtp/otpApi';
import { Formik } from 'formik';
import { validate } from '../../../utils/validationForm';
import InputForm from '../inputForm/InputForm';
import { okErrorCode } from '../enum/errorCode';
import SubCard from '../subCard/SubCard';
import { signInApi } from '../../../api/signIn/signinApi';
import { signUpApi } from '../../../api/signUp/signupApi';
import { IoMdClose } from 'react-icons/io';
import backgroundImage from '../../../Assets/login-background.png';
import formLogoImage from '../../../Assets/logo-form-login.png';
import { faEnvelope, faEye, faEyeSlash, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { relative } from 'path';
import { log } from 'console';

export type SideNavList = {
  name: string;
  path: string;
};
const Login: FC = () => {
  const verificationStatus = useSelector(selectOtpVerification);
  const [otpVerify, setOtpVerify] = useState(verificationStatus?.status);
  const [otpSent, setOtpSent] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [usedEmailMessage, setUsedEmailMessage] = useState('');
  const [isForgetClicked, setForget] = useState(false);
  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [detail] = useState({
    email: '',
    password: '',
    userName: '',
    type: [''],
    otp: ''
  });
  const [have, setHave] = useState(location?.pathname?.includes('/login'));
  const dispatch = useDispatch();

  // Timer State
  const [otpModal, setOtpModal] = useState(false);
  const [timer, setTimer] = useState(180);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const [newPasswordModal, setnewPasswordModal] = useState(false);

  const [password, setPassword] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    console.log('Updated type:', detail.type);
  }, [detail.type]);

  // Timer Effect
  useEffect(() => {
    if (otpSent && timer > 0) {
      const countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }
  }, [otpSent, timer]);


  useEffect(() => {
    if (otpSent) {
      setOtpVerify(verificationStatus?.status);
    }
  }, [verificationStatus]);

  useEffect(() => {
    setHave(location.pathname.includes('/login'));
  }, [location]);

  const signIN = async (values: any) => {
    try {
      const response: any = await signInApi(values);
      setCookie('user', {
        email: response?.data.email,
        token: response?.data.token,
      });
      dispatch({
        type: SET_LOGIN_DATA,
        payload: response.data || detail,
      });
      if (response.data.roles[0] === 'ROLE_HOSTEL') {
        history.push('/addHostel');
      }
      if (response.data.roles[0] === 'ROLE_HOTEL') {
        history.push('/addHotel');
      }
      if (response.data.roles[0] === 'ROLE_USER') {
        history.push('/profile');
      }
      if (response.data.roles[0] === 'ROLE_MODERATOR') {
        history.push('/crm');
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const signUp = async (values: any) => {
    try {
      const response: any = await signUpApi(values);
      okErrorCode.includes(response.status) && history.push('/login');
      // setnewPasswordModal(false);
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const verify = async (values: any) => {
    try {
      const data = await otpVerification(values.email, values.otp);
      dispatch({
        type: OTP_VERIFICATION,
        payload: { status: data?.status === 204 },
      });
      setOtpSent(false);
    } catch (error: any) {
      setOtpSent(true);
      console.log(error.message);
    }
  };
  const sendOtp = async (email: string) => {
    try {
      const response = await getOtpApi(email); // Call your OTP API
      if (response.status === 204) {
        setOtpSent(true);
        setIsResendDisabled(true);
        setTimer(response?.data?.otpValidity || 180); // Use API-provided validity time in second
        setOtpModal(true);
      } else {
        setOtpSent(false);
        console.error('Failed to send OTP:', response?.response?.data?.errors[0]?.errorMessage);
      }
    } catch (error: any) {
      console.error('Error sending OTP:', error.message);
    }
  };
  const verifyOtp = async (email: string, otp: string) => {
    console.log("verify otp called");
    
    try {
      const response = await otpVerification(email, otp); // Call your OTP verification API
      if (response?.status === 204) {
        dispatch({
          type: OTP_VERIFICATION,
          payload: { status: true },
        });
        setOtpVerify(true);
        setOtpSent(false);
        setOtpModal(false);
        setShowPassword(true); // Proceed to password and role setup
        setnewPasswordModal(true);
      } else {
        console.error('OTP verification failed');
      }
    } catch (error: any) {
      console.error('Error verifying OTP:', error.message);
    }
  };
  const resendOtp = (email: string) => {
    setOtpSent(false);
    console.log("in resend: ",email);
    
    sendOtp(email);
  };

  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // State to store OTP values

  const handleOtpChange = (value: string, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1); // Allow only one character
    setOtp(newOtp);

    // Move focus to the next box if value is entered
    if (value && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`) as HTMLInputElement;
      nextInput.focus(); // Only focus if the element exists
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key } = e;

    // Handle backspace key
    if (key === "Backspace") {
      if (!otp[index] && index > 0) {
        // Move focus to the previous box if current is empty
        const previousInput = document.getElementById(`otp-${index - 1}`) as HTMLInputElement
        previousInput.focus();
      }
      const newOtp = [...otp];
      newOtp[index] = ""; // Clear current box
      setOtp(newOtp);
    }
  };

  return (
    <Col
      className="min-vh-100 justify-content-center align-items-center d-flex"
      // style={{ background: '#b5f4c5' }}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        position: 'relative'
      }}
      >
      <Modal.Body
        className="p-3 align-items-center justify-content-center d-flex"
        style={{
          position: 'absolute'
        }}
        >
        
        <Formik
          initialValues={detail}
          validationSchema={validate}
          onSubmit={(values) => {
            if (have) {
              console.log("if")
              signIN(values); // Handle login
            } else if(!have && otpModal){
              console.log("elif")
              verifyOtp(values.email,otp.join());
            } 
            else {
              console.log("else")
              console.log("else: ",values)
              //signUp(values); // Handle registration
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
            setValues,
          }) => {
            const isFormValid = (have && values.email && values.password) || 
              (!have && !otpModal && values.userName && values.email) ||
             (otpModal && otp.some((val) => val !== "") ||
             (!have && !otpModal && newPasswordModal && values.userName && values.email && values.type.length !=0));
            return (
            <div
              style={{
                padding: "1rem",
                backgroundColor: "white",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                width: "100%",
                maxWidth: "400px",
                borderRadius: "10px",
                textAlign: "center",
              }}>
              {/* Logo Section */}
              <div style={{ marginBottom: "1.5rem" }}>
                <img
                  src={require("../../../Assets/logo-form-login.png")}
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "auto",
                    margin: "0 auto",
                  }}
                />
              </div>

              {/* Title */}
              <h5
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  marginBottom: "1.5rem",
                  color: "#1d3557",
                }}
              >
                {/* {have ? "Login" : "Create Account"} */}
                {
                  have
                    ? "Login"
                    : otpModal
                    ? "Otp verification"
                    : newPasswordModal
                    ? "Set a new password"
                    : "Create Account"
                }
              </h5>

              {/* Subtitle */}
              {(!have && otpModal && 
                <>
                <p
                  style={{
                    fontSize: '0.9rem',
                    color: '#6c757d',
                    marginBottom: '1rem',
                    textAlign: 'left'
                  }}
                >
                  Enter the code sent to <b>{detail.email}</b>
                </p>
                <div style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                   }}>
                    {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                </div>
                </>
              )}
              
              

              {/* Form */}
              <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                {/* Username Field (Only for Register) */}
                {!have && !otpModal && (
                  <div className="mb-3 position-relative">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="position-absolute"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "12px",
                        transform: "translateY(-50%)",
                        fontSize: "1.2rem",
                        color: "#6c757d",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        marginLeft: "7px",
                        marginRight: "7px",
                        top: "50%",
                        left: "30px",
                        transform: "translateY(-50%)",
                        fontSize: "1.2rem",
                        color: "#6c757d",
                      }}
                    >
                      |
                    </div>
                    <input
                      type="text"
                      name="userName"
                      placeholder="Enter Your Username"
                      value={values.userName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control pl-5"
                      style={{
                        padding: "10px 12px 10px 50px",
                        border: "1px solid #ced4da",
                        borderRadius: "8px",
                        backgroundColor: "#f1faee",
                      }}
                    />
                    {touched.userName && errors.userName && (
                      <div className="text-danger small">
                        {errors.userName}
                      </div>
                    )}
                  </div>
                )}

                {/* Email Field */}
                {(have || (!otpModal && !newPasswordModal)) &&(
                  <div className="mb-3 position-relative">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="position-absolute"
                    style={{
                      top: "50%",
                      left: "12px",
                      transform: "translateY(-50%)",
                      fontSize: "1.2rem",
                      color: "#6c757d",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      marginLeft: "7px",
                      marginRight: "7px",
                      top: "50%",
                      left: "30px",
                      transform: "translateY(-50%)",
                      fontSize: "1.2rem",
                      color: "#6c757d",
                    }}
                  >
                    |
                  </div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control pl-5"
                    style={{
                      padding: "10px 12px 10px 50px",
                      border: "1px solid #ced4da",
                      borderRadius: "8px",
                      backgroundColor: "#f1faee",
                    }}
                  />
                  {touched.email && errors.email && (
                    <div className="text-danger small">
                      {errors.email}
                    </div>
                  )}
                </div>

                )}
                

                {/* Password Field */}
                {((have && !otpModal) || newPasswordModal) && (
                  <div className="mb-3 position-relative">
                    <FontAwesomeIcon
                      icon={faLock}
                      className="position-absolute"
                      style={{
                        top: "50%",
                        left: "12px",
                        transform: "translateY(-50%)",
                        fontSize: "1.2rem",
                        color: "#6c757d",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        marginLeft: "7px",
                        marginRight: "7px",
                        top: "50%",
                        left: "30px",
                        transform: "translateY(-50%)",
                        fontSize: "1.2rem",
                        color: "#6c757d",
                      }}
                    >
                      |
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control pl-5"
                      style={{
                        padding: "10px 12px 10px 50px",
                        border: "1px solid #ced4da",
                        borderRadius: "8px",
                        backgroundColor: "#f1faee",
                      }}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      onClick={() => setShowPassword(!showPassword)}
                      className="position-absolute"
                      style={{
                        top: "50%",
                        right: "12px",
                        transform: "translateY(-50%)",
                        fontSize: "1.2rem",
                        color: "#6c757d",
                        cursor: "pointer",
                      }}
                    />
                    {touched.password && errors.password && (
                      <div className="text-danger small">
                        {errors.password}
                      </div>
                    )}
                  </div>
                )}

                {/* OTP field*/}
                {!have && otpModal && (
                  <>
                    <div
                      className="mb-3 position-relative"
                      // style={{
                      //   display: 'flex',
                      //   justifyContent: 'space-between', // Ensures boxes start and end within the div
                      //   gap: `${window.innerWidth > 768 ? 20 : window.innerWidth > 480 ? 10 : 5}px`, // Responsive gap in pixels
                      //   marginBottom: '1rem',
                      //   width: '100%', // Ensures the div spans its container
                      // }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between", // Align boxes horizontally with equal spacing
                        gap: `${window.innerWidth > 768 ? 12 : window.innerWidth > 480 ? 10 : 5}px`, // Responsive gap between inputs
                        marginBottom: "1rem",
                        width: "100%", // Ensures the div spans its container
                        overflow: "clip", // Prevent the OTP boxes from overflowing
                      }}
                      
                    >

                    {/* {[...Array(4)].map((_, index) => ( */}
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        id={`otp-${index}`}
                        type="number"
                        maxLength={1}
                        value={value}
                        onChange={(e) => handleOtpChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        // style={{
                        //   width: `${window.innerWidth > 768 ? 60 : window.innerWidth > 480 ? 50 : 40}px`, // Responsive width
                        //   height: `${window.innerWidth > 768 ? 60 : window.innerWidth > 480 ? 50 : 40}px`, // Responsive height
                        //   textAlign: "center",
                        //   fontSize: `${window.innerWidth > 768 ? 2 : window.innerWidth > 480 ? 1.5 : 1}rem`, // Responsive font size
                        //   border: "1px solid #ced4da",
                        //   borderRadius: "8px",
                        //   backgroundColor: "#f1faee",
                        // }}
                        style={{
                          flex: "1 1 auto", // Allow each input to grow but still maintain equal width
                          width: "60%",      // Set width as a percentage of the container
                          height: "70%", // Responsive height
                          textAlign: "center",
                          // fontSize: `${window.innerWidth > 768 ? 2 : window.innerWidth > 480 ? 1.5 : 1}rem`, // Responsive font size
                          border: "1px solid #ced4da",
                          borderRadius: "8px",
                          backgroundColor: "#f1faee",
                        }}
                        />
                    ))}

                    </div>
                    <p
                      style={{
                        fontSize: '0.9rem',
                        color: '#6c757d',
                        marginBottom: '1.5rem',
                      }}
                    >
                      Didn’t receive the OTP?{' '}
                     <span
                      //  onClick={resendOtp(values.email)}
                       onClick={(e) => {resendOtp(values.email)}}
                       style={{
                         color: isResendDisabled ? '#ccc' : '#EA3560',
                         cursor: isResendDisabled ? 'not-allowed' : 'pointer',
                         textDecoration: 'underline',
                       }}
                     >
                       Resend
                     </span>
                    </p>
                  </>

                )}

                {/* confirm password */}
                {/* {newPasswordModal && (
                  <div className="mb-3 position-relative">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="position-absolute"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "12px",
                        transform: "translateY(-50%)",
                        fontSize: "1.2rem",
                        color: "#6c757d",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        marginLeft: "7px",
                        marginRight: "7px",
                        top: "50%",
                        left: "30px",
                        transform: "translateY(-50%)",
                        fontSize: "1.2rem",
                        color: "#6c757d",
                      }}
                    >
                      |
                    </div>
                    <input
                      type="password"
                      name="confirm password"
                      placeholder="Retype your password"
                      value={values.confirmPassword}
                      // pattern={password} // This ensures the input matches the password
                      title="Passwords must match"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control pl-5"
                      style={{
                        padding: "10px 12px 10px 50px",
                        border: "1px solid #ced4da",
                        borderRadius: "8px",
                        backgroundColor: "#f1faee",
                      }}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      onClick={() => setShowPassword(!showPassword)}
                      className="position-absolute"
                      style={{
                        top: "50%",
                        right: "12px",
                        transform: "translateY(-50%)",
                        fontSize: "1.2rem",
                        color: "#6c757d",
                        cursor: "pointer",
                      }}
                    />
                    {touched.userName && errors.userName && (
                      <div className="text-danger small">
                        {errors.userName}
                      </div>
                    )}
                    {touched.confirmPassword && errors.confirmPassword && (
                      <div className="text-danger small">
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                )} */}

                {/*user type */}
                {!have && newPasswordModal && (
                  <div className="mb-3 position-relative">
                    {/* <Col className="col-4 p-0 m-0"> */}
                      <Dropdown
                        onSelect={(eventKey: any) => {
                          setValues({
                            ...values,
                            type: [eventKey?.toLowerCase()],
                          });
                          console.log('Updated type:', [
                            eventKey?.toLowerCase(),
                          ]); // Debugging
                          console.log("set type=",detail.type);
                          console.log("set type value=",values.type);
                          
                        }}>
                        <Dropdown.Toggle
                          className="border-0 round-edges d-inline h2 py-1 m-0 text-dark"
                          style={{ background: '#f1faee', textAlign: 'left' }}
                          id="dropdown-basic">
                          {/* {values?.type?.length > 0
                            ? String(values?.type).toUpperCase()
                            : 'Select user type'} */}
                            {values?.type[0] && values?.type[0] !== ''
                            ? String(values?.type[0]).toUpperCase() // If a value exists in the array, show it
                            : 'Select user type'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          style={{position: 'relative' }}
                        >
                          <Dropdown.Item
                            className={'text-dark text-decoration-none'}
                            eventKey={'User'}>
                            User
                          </Dropdown.Item>
                          <Dropdown.Item
                            className={'text-dark text-decoration-none'}
                            eventKey={'Hotel'}>
                            Hotel
                          </Dropdown.Item>
                          <Dropdown.Item
                            className={'text-dark text-decoration-none'}
                            eventKey={'Hostel'}>
                            Hostel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    {/* </Col> */}
                  </div>
                )}
              

                {/* Submit Button with Conditional Logic */}
                {(have && !otpModal) && (
                  <Button
                    className="btn round-edges pointer h2 h-100 py-1"
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      backgroundColor: "#EA3560",
                      color: "white",
                      // fontWeight: "bold",
                      border: "none",
                      fontSize: "1rem",
                      // cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        !Object.keys(errors).includes('email') &&
                        !Object.keys(errors).includes('password')
                      ) {
                        signIN(values);
                      }
                    }}
                    disabled={!isFormValid}
                  >
                    <span className="h5 p-0 m-0">Log In</span>
                  </Button>
                )} 
                
                { !have && !otpModal && !newPasswordModal &&(
                  <>
                    {otpVerify && (
                  <Button
                    className="btn round-edges pointer h2 h-100 py-1"
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      backgroundColor: "#EA3560",
                      color: "white",
                      // fontWeight: "bold",
                      border: "none",
                      fontSize: "1rem",
                      // cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();

                      console.log("here");
                      console.log(Object.keys(errors));
                      // let er = Object.keys(errors);
                      // console.log("err: ",er);
                      // console.log("errr: ",er.at(0));
                      if (Object.keys(errors).length === 0 || Object.keys(errors).at(0) === 'password') {
                        console.log("here signup")
                        // signUp(values);
                        sendOtp(values.email);
                      }
                    }}
                    disabled={!isFormValid}
                  >
                        <span className="h5 p-0 m-0">Sign Up</span>
                    </Button>
                    )}
                  </>
                )}

                {(!have && otpModal) && (
                  <Button
                    className="btn round-edges pointer h2 h-100 py-1"
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      backgroundColor: "#EA3560",
                      color: "white",
                      // fontWeight: "bold",
                      border: "none",
                      fontSize: "1rem",
                      // cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (otp.some((val)=> val !== "")) {
                        console.log("sdsd");
                        console.log(otp.join(""));
                        verifyOtp(values.email, otp.join(""));
                      }else{
                        console.log("called");
                        otp.some((val)=> console.log(val))
                      }
                    }}
                    disabled={!isFormValid}
                  >
                    <span className="h5 p-0 m-0">Submit</span>
                  </Button>
                )}

                {/*set password and type */}
                {!have && newPasswordModal &&(
                  <>
                    {(
                  <Button
                    className="btn round-edges pointer h2 h-100 py-1"
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      backgroundColor: "#EA3560",
                      color: "white",
                      // fontWeight: "bold",
                      border: "none",
                      fontSize: "1rem",
                      // cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();

                      console.log("here user select");
                      console.log("er",Object.keys(errors));
                      console.log("err",Object.keys(errors).length);
                      console.log("type:", detail.type);
                      console.log(values.email, values.password, values.type, values.userName)
                      
                      if (Object.keys(errors).length === 0) {
                        console.log("here signup");
                        console.log(values.email, values.password, values.type, values.userName)
                        signUp(values);
                        
                      }
                    }}
                    disabled={!isFormValid}
                  >
                        <span className="h5 p-0 m-0">Sign Up</span>
                    </Button>
                    )}
                  </>
                )}
                
              </Form>

              {/* Switch Between Login and Register */}
              <div className="mt-3">
                {have ? (
                  <p className="small">
                    Don't have an account?{" "}
                    <span
                    className="text font-weight-bold"
                      style={{
                        color: "#1d3557",
                        cursor: "pointer",
                        borderRadius: "8px",
                        fontSize: "1rem",
                      }}
                      onClick={() => {
                        setValues(detail);
                        setHave(false);
                        setOtpVerify(true);
                        // setOtpModal(true)
                        // setOtpSent(true);
                        // setnewPasswordModal(true);
                      }}
                    >
                      Sign Up
                    </span>
                  </p>
                ) : (
                  <p className="small">
                    Already have an account?{" "}
                    <span
                      className="text font-weight-bold"
                      style={{
                        // width: "100%",
                        borderRadius: "8px",
                        color: "#1d3557",
                        // fontWeight: "bold",
                        // border: "none",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setValues(detail);
                        setHave(true);
                        setOtpModal(false);
                        setOtpVerify(false);
                        setnewPasswordModal(false);
                      }}
                    >
                      Login
                    </span>
                  </p>
                )}
              </div>
            </div>
          )}}
        </Formik>

      </Modal.Body>

      
    </Col>
  );
};

export default Login;

{/* <Modal show={isForgetClicked} onHide={() => setForget(false)} centered>
        <Modal.Body className="flex-row d-flex justify-content-between w-100">
          <p className="p-0 m-0 align-self-center">
            Kindly drop us a mail in
            <span
              className={'bold pointer'}
              onClick={(e) => {
                window.location.href = 'mailto:Connect.helpdesk@lyamii.com';
                e.preventDefault();
              }}>
              "connect.helpdesk@lyamii.com"
            </span>{' '}
          </p>
          <span onClick={() => setForget(false)} className={' p-0 m-0 normal'}>
            <IoMdClose className="p-0 m-0" />
          </span>
        </Modal.Body>
      </Modal> */}