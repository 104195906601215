import React, { useEffect, useState } from "react";
import { Card, Container, Carousel, Button, Col, Row } from "react-bootstrap";
import styles from "./Destinations.module.scss"; // Correct import path
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { tours } from "../../../state/selectors/selectTourData";
import {
  SET_TOUR_PACKAGE_ID,
  selectedTourDataDto,
} from "../../../state/actions/types/tourDataActionType";
import { TbWorldSearch } from "react-icons/tb";
import { selectScreenSize } from "../../../state/selectors/selectScreenSize";

import lyami_tour_image from '../../../Assets/lyami_tour_logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import clock_icon_red from '../../../Assets/clock-icon-red.png';
import location_icon_red from '../../../Assets/location_icon_red.png';

const Destinations = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const indianTours = useSelector(tours);

  const [visibleTours, setVisibleTours] = useState(3);
  const [tourlist, setTourList] = useState<selectedTourDataDto[]>(indianTours);
  // useEffect(() => {
  //   indianTours?.length > 0 && setTourList(indianTours);
  // }, [indianTours]);

  useEffect(() => {
    if (indianTours?.length > 0) setTourList(indianTours);
  }, [indianTours]);

  // Load more tours on clicking "View More"
  const handleViewMore = () => {
    setVisibleTours((prev) => Math.min(prev + 3, tourlist.length));
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine styles based on screen width
  const isSmallDevice = screenWidth <= 400;
  // const isTablet = screenWidth > 768 && screenWidth <= 1024;
  // useEffect(() => {
  //   if (indianTours?.length > 0) setTourList(indianTours);

  //   // Adjust initial visible cards based on screen size
  //   if (window.innerWidth > 768) {
  //     setVisibleTours(3); // Show 3 cards initially for larger screens
  //   }else{
  //     setVisibleTours(1);
  //   }
  // }, [indianTours]);

  // // Adjust `visibleTours` on "View More" click
  // const handleViewMore = () => {
  //   setVisibleTours((prev) => Math.min(prev + (window.innerWidth <= 768 ? 1 : 3), tourlist.length));
  // };

  const screenSize = useSelector(selectScreenSize);


  // const tours = [
  //   {
  //     id: 1,
  //     country: "India",
  //     duration: "11N/12D",
  //     cities: "• 3N Delhi • 5N Manali • 3N Shimla",
  //     features: [
  //       "Round Trip Flights",
  //       "Intercity Car Transfers",
  //       "3 Star Hotels",
  //       "Airport Transfers",
  //       "7 Activities",
  //       "Selected Meals",
  //     ],
  //     price: "₹1,00,000",
  //     imageUrl:
  //       "https://images.unsplash.com/photo-1620403724159-40363e84a155?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fG1vdW50YWluJTIwaGQlMjBpbWFnZXxlbnwwfHwwfHx8MA%3D%3D",
  //   },
  //   {
  //     id: 2,
  //     country: "South Africa",
  //     duration: "10N/11D",
  //     cities: "• 3N Cape Town • 4N Johannesburg • 3N Durban",
  //     features: [
  //       "Round Trip Flights",
  //       "Intercity Car Transfers",
  //       "4 Star Hotels",
  //       "Airport Transfers",
  //       "8 Activities",
  //       "Selected Meals",
  //     ],
  //     price: "₹2,20,000",
  //     imageUrl:
  //       "https://plus.unsplash.com/premium_photo-1669131388697-fab59ba90484?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8c2VhfGVufDB8fDB8fHww",
  //   },
  //   {
  //     id: 3,
  //     country: "Brazil",
  //     duration: "9N/10D",
  //     cities: "• 3N Rio De Janeiro • 3N São Paulo • 3N Salvador",
  //     features: [
  //       "Round Trip Flights",
  //       "Intercity Car Transfers",
  //       "4 Star Hotels",
  //       "Airport Transfers",
  //       "7 Activities",
  //       "Selected Meals",
  //     ],
  //     price: "₹1,90,000",
  //     imageUrl:
  //       "https://images.unsplash.com/photo-1491378630646-3440efa57c3b?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHNlYXxlbnwwfHwwfHx8MA%3D%3D",
  //   },
  //   {
  //     id: 4,
  //     country: "China",
  //     duration: "12N/13D",
  //     cities: "• 3N Beijing • 4N Shanghai • 3N Guangzhou • 2N Hong Kong",
  //     features: [
  //       "Round Trip Flights",
  //       "Intercity Car Transfers",
  //       "5 Star Hotels",
  //       "Airport Transfers",
  //       "10 Activities",
  //       "Selected Meals",
  //     ],
  //     price: "₹3,50,000",
  //     imageUrl:
  //       "https://images.unsplash.com/photo-1483683804023-6ccdb62f86ef?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHNlYXxlbnwwfHwwfHx8MA%3D%3D",
  //   },
  //   {
  //     id: 5,
  //     country: "Argentina",
  //     duration: "8N/9D",
  //     cities: "• 3N Buenos Aires • 3N Mendoza • 2N Iguazu Falls",
  //     features: [
  //       "Round Trip Flights",
  //       "Intercity Car Transfers",
  //       "4 Star Hotels",
  //       "Airport Transfers",
  //       "6 Activities",
  //       "Selected Meals",
  //     ],
  //     price: "₹1,60,000",
  //     imageUrl:
  //       "https://images.unsplash.com/photo-1628371655947-f65194a1bd37?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGhhcHB5fGVufDB8fDB8fHww",
  //   },
  //   {
  //     id: 6,
  //     country: "New Zealand",
  //     duration: "14N/15D",
  //     cities: "• 3N Auckland • 4N Rotorua • 3N Wellington • 4N Queenstown",
  //     features: [
  //       "Round Trip Flights",
  //       "Intercity Car Transfers",
  //       "5 Star Hotels",
  //       "Airport Transfers",
  //       "12 Activities",
  //       "Selected Meals",
  //     ],
  //     price: "₹3,80,000",
  //     imageUrl:
  //       "https://plus.unsplash.com/premium_photo-1669704099116-a325b4d6186f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGhhcHB5fGVufDB8fDB8fHww",
  //   },
  // ];

  return (
    // <Container
    //   fluid
    //   className="d-flex flex-column justify-content-center align-items-center"
    //   style={{
    //     marginTop: 40,
    //     marginBottom: 40,
    //   }}
    // >
    //   <h2
    //     className="bold d-flex justify-content-center align-items-center"
    //     style={{
    //       fontSize: 45,
    //       fontWeight: "400",
    //       marginTop: 20,
    //       marginBottom: 40,
    //       color: "#686868",
    //       textAlign: "center",
    //       fontFamily: "Poppins, sans-serif",
    //       position: "relative",
    //     }}
    //   >
    //     <img
    //      className="bold d-flex "
    //       src= {lyami_tour_image}
    //       alt="tour logo"
    //       style={{
    //         height: "7vh",
    //         width: "auto"
    //       }}
    //       onClick={() => {
    //         history.push("/explore");
    //       }}
    //     >
    //     </img>
    //     <span
    //       onClick={() => {
    //         history.push("/explore");
    //       }}
    //     >
    //       Lyamii Tours
    //     </span>
    //     <TbWorldSearch
    //       onClick={() => {
    //         history.push("/explore");
    //       }}
    //       className="top-0 align-items-center"
    //     />
    //     <span
    //       style={{
    //         display: "block",
    //         width: 90,
    //         height: 6,
    //         backgroundColor: "#75abdc",
    //         position: "absolute",
    //         bottom: -10,
    //         left: "50%",
    //         transform: "translateX(-50%)",
    //         borderRadius: "0px 0px 5px 5px",
    //         opacity: 0.7,
    //       }}
    //     />
    //   </h2>
    //   <div className="scrolling-wrapper p-4 w-90 align-self-center row flex-row flex-nowrap">
    //     {tourlist.map((tour, index: number) => {
    //       return (
    //         <Col
    //           md={6}
    //           lg={4}
    //           sx={6}
    //           sm={12}
    //           key={index}
    //           className="expand col-12 d-inline-block position-relative"
    //         >
    //           <Card
    //             className={`${styles.carouselCard}`}
    //             style={{ minWidth: "290px" }}
    //           >
    //             <Card.Img
    //               style={{ minHeight: "290px" }}
    //               variant="top"
    //               src={
    //                 tour?.imageUri?.length > 0 && tour.imageUri[0] !== ""
    //                   ? `https://drive.google.com/thumbnail?sz=w2000&id=${tour.imageUri[0]}`
    //                   : "https://drive.google.com/thumbnail?sz=w2000&id=1j8giF6uvrDsI-yfMYZFWxdBGe0wirl6w"
    //               }
    //               alt={tour.title}
    //               className={styles.cardImage}
    //             />
    //             <Card.Body
    //               className={`align-items-between d-grid ${styles.cardTextOverlay}`}
    //             >
    //               <div className="d-flex w-100 align-items-center justify-content-between align-content-between">
    //                 <Card.Title
    //                   className={`position-absolute bg-white p-0 m-0 ${
    //                     screenSize?.screenSize < 767
    //                       ? "normal"
    //                       : screenSize?.screenSize > 767 &&
    //                         screenSize?.screenSize < 969
    //                       ? "fs-medium"
    //                       : "fs-auto"
    //                   }`}
    //                   style={{
    //                     fontSize: 25,
    //                     fontWeight: "700",
    //                     zIndex: "1",
    //                     color: "#75abdc",
    //                     whiteSpace: "pre",
    //                   }}
    //                 >
    //                   {tour.title}
    //                 </Card.Title>
    //                 <hr
    //                   style={{
    //                     border: "0.5px solid #D0D0D0",
    //                     width: "100%",
    //                     height: "1px",
    //                     zIndex: "0",
    //                     // margin: "10px 0",
    //                   }}
    //                 />
    //                 <Card.Subtitle
    //                   style={{
    //                     fontSize: 13,
    //                     height: "25px",
    //                     fontWeight: "400",
    //                     color: "#75abdc",
    //                     border: "0.2px solid #75abdc",
    //                     padding: "2px 5px",
    //                     borderRadius: "3px",
    //                     textAlign: "center",
    //                     alignContent: "center",
    //                   }}
    //                 >
    //                   {tour.noOfDays}
    //                 </Card.Subtitle>
    //               </div>
    //               <Col className="pt-2">
    //                 <div className="d-flex p-0 m-0 flex-row justify-content-between">
    //                   <Row className="list-unstyled">
    //                     {tour.destinations
    //                       .slice(0, 4)
    //                       .map((destination, index) => (
    //                         <li
    //                           key={index}
    //                           style={{
    //                             fontSize: 13,
    //                             fontWeight: "400",
    //                             color: "#818181",
    //                             marginTop: 5,
    //                           }}
    //                           className="col px-0 mx-0"
    //                         >
    //                           <span
    //                             style={{
    //                               color: "#75abdc",
    //                             }}
    //                           >
    //                             •
    //                           </span>{" "}
    //                           {destination}
    //                         </li>
    //                       ))}
    //                     <span
    //                       style={{
    //                         fontSize: 13,
    //                         fontWeight: "400",
    //                         color: "#818181",
    //                         marginTop: 5,
    //                       }}
    //                       className="col"
    //                     >
    //                       {tour.destinations.length > 5 &&
    //                         tour.destinations.length - 5 + "+"}
    //                     </span>
    //                   </Row>
    //                 </div>
    //                 {/* </Card.Text> */}
    //                 <hr
    //                   style={{
    //                     border: "0.5px solid #D0D0D0",
    //                     width: "100%",
    //                     margin: "10px 0",
    //                   }}
    //                 />
    //                 <div className="d-flex justify-content-between align-items-center">
    //                   <p
    //                     className="d-flex align-items-center p-0 my-2"
    //                     style={{
    //                       fontSize: 16,
    //                       fontWeight: "700",
    //                       color: "#05A1A5",
    //                     }}
    //                   >
    //                     <span>
    //                       <select
    //                         style={{
    //                           WebkitAppearance: "none",
    //                           MozAppearance: "none",
    //                           background: "transparent",
    //                           // fontSize: "16px",
    //                           fontWeight: "700",
    //                           color: "rgb(134, 133, 133)",
    //                         }}
    //                         className=" p-0 pointer border border-0 w-100 small"
    //                         aria-label="Default select example"
    //                       >
    //                         {tour?.packagePrice?.map(
    //                           (price: string, index: number) => (
    //                             <option
    //                               key={index}
    //                               value={price}
    //                               className="small"
    //                               style={{
    //                                 // fontSize: "16px",
    //                                 fontWeight: "700",
    //                                 color: "rgb(134, 133, 133)",
    //                               }}
    //                             >
    //                               {price}
    //                             </option>
    //                           )
    //                         )}
    //                       </select>
    //                     </span>
    //                     <span
    //                       className="small"
    //                       style={{
    //                         // fontSize: 16,
    //                         fontWeight: "700",
    //                         color: "#868585",
    //                       }}
    //                     >
    //                       {" "}
    //                       / Person
    //                     </span>
    //                   </p>
    //                   <Button
    //                     style={{
    //                       backgroundColor: "#75abdc",
    //                       fontWeight: "700",
    //                       fontSize: 15,
    //                       borderRadius: 0,
    //                       borderWidth: 0,
    //                     }}
    //                     onClick={() => {
    //                       dispatch({
    //                         type: SET_TOUR_PACKAGE_ID,
    //                         payload: tour.packageId,
    //                       });
    //                       history.push(`/tour-detail:${tour.packageId}`);
    //                     }}
    //                   >
    //                     View More
    //                   </Button>
    //                 </div>
    //               </Col>
    //             </Card.Body>
    //           </Card>
    //         </Col>
    //       );
    //     })}
    //   </div>
    // </Container>

    <Container
      fluid
      className="d-flex flex-column justify-content-center align-items-center ${styles.mobileContainer}`"
      style={{
        marginTop: 40,
        marginBottom: 40,
      }}
    >
      {/* Title Section */}
      <img
         className="bold d-flex "
          src= {lyami_tour_image}
          alt="tour logo"
          style={{
            height: "7vh",
            width: "auto"
          }}
          onClick={() => {
            history.push("/explore");
          }}
        >
      </img>

      {/* Tours Section */}
      <div className="scrolling-wrapper p-2 w-90 align-self-center row flex-row flex-wrap">
        {tourlist.slice(0, visibleTours).map((tour, index: number) => {
          return (
            <Col
              md={6}
              lg={4}
              key={index}
              className="expand col-12 d-inline-block position-relative"
            >
              <Card className={`${styles.carouselCard}`} 
              // style={{ minWidth: "290px" }}
              >
                <Card.Img
                  style={{ minHeight: "290px" }}
                  variant="top"
                  src={
                    tour?.imageUri?.length > 0 && tour.imageUri[0] !== ""
                      ? `https://drive.google.com/thumbnail?sz=w2000&id=${tour.imageUri[0]}`
                      : "https://drive.google.com/thumbnail?sz=w2000&id=1j8giF6uvrDsI-yfMYZFWxdBGe0wirl6w"
                  }
                  alt={tour.title}
                  className={styles.cardImage}
                />
                <Card.Body
                  className={`align-items-between d-flex flex-column ${styles.cardTextOverlay}`}
                >
                  <div className="d-flex w-100 align-items-center justify-content-between align-content-between">
                    <Card.Title
                      className={`DM Sans position-relative bg-white p-0 m-0 ${
                        screenSize?.screenSize < 767
                          ? "normal"
                          : screenSize?.screenSize > 767 &&
                            screenSize?.screenSize < 969
                          ? "fs-medium"
                          : "fs-auto"
                      }`}
                      style={{
                        fontSize: 23,
                        fontWeight: "700",
                        zIndex: "1",
                        color: "#064055",
                        // whiteSpace: "pre",
                        whiteSpace: "normal", // Allow the title to wrap
                        lineHeight: "1.2", // Adjust spacing between lines
                        maxWidth: "75%", // Prevent the title from expanding too much
                        overflowWrap: "break-word",
                        textAlign: "left"
                      }}
                    >
                      {tour.title}
                    </Card.Title>
                    <hr
                      style={{
                        border: "0.5px solid #D0D0D0",
                        // width: "100%",
                        height: "1px",
                        zIndex: "0",
                        // margin: "10px 0",
                      }}
                    />
                    
                    <Card.Subtitle
                      style={{
                        fontSize: 13,
                        height: "25px",
                        fontWeight: "400",
                        color: "#064055",
                        // border: "0.2px solid #75abdc",
                        padding: "2px 5px",
                        borderRadius: "3px",
                        textAlign: "center",
                        alignContent: "center",
                        marginLeft: "auto",
                        display: "flex",
                        gap: "3px",
                      }}
                    >

                      <img src={clock_icon_red} 
                      alt="clock icon"
                      style={{
                        marginBottom: "2px",
                        marginRight: "3px"
                      }}
                      />
                      {tour.noOfDays}
                      <span> days</span>
                    </Card.Subtitle>
                  </div>
                  <Col className="pt-2">
                    <div className="d-flex p-0 m-0 flex-row justify-content-between">
                    
                      <Row className="list-unstyled">
                      
                      <FontAwesomeIcon
                      icon={faLocationDot}
                      size="sm"
                      className="col px-0 mx-0"
                      style={{
                        marginTop: "6px",
                        color: "#ea3560",
                        paddingRight: "4px"
                      }}
                      />
                        {tour.destinations
                          .slice(0, 4)
                          .map((destination, index) => (
                            <li
                              key={index}
                              style={{
                                fontSize: 13,
                                fontWeight: "400",
                                color: "#818181",
                                marginTop: 5,
                              }}
                              className="col px-0 mx-0 DM Sans"
                            >
                              {index !== 0 && (
                                <span
                                  style={{
                                    color: "#75abdc",
                                  }}
                                >,
                                </span>
                              )}
                              {index !== 0 && (
                                <span>{" "}</span>
                              )}
                              {/* {" "} */}
                              {destination}
                            </li>
                          ))}
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "400",
                            color: "#818181",
                            marginTop: 5,
                          }}
                          className="col"
                        >
                          {tour.destinations.length > 5 &&
                            tour.destinations.length - 5 + "+"}
                        </span>
                      </Row>
                    </div>
                    
                    <hr
                      style={{
                        border: "0.5px solid #D0D0D0",
                        width: "100%",
                        margin: "10px 0",
                      }}
                    />
                    <div className="d-flex justify-content-between align-items-center">
                      <p
                        className="d-flex align-items-center p-0 my-2"
                        style={{
                          fontSize: 16,
                          fontWeight: "700",
                          color: "#05A1A5",
                        }}
                      >
                        <span>
                          <select
                            style={{
                              WebkitAppearance: "none",
                              MozAppearance: "none",
                              background: "transparent",
                              // fontSize: "16px",
                              fontWeight: "700",
                              color: "#064055",
                            }}
                            className=" p-0 pointer border border-0 w-100 small DM Sans"
                            aria-label="Default select example"
                          >
                            {tour?.packagePrice?.map(
                              (price: string, index: number) => (
                                <option
                                  key={index}
                                  value={price}
                                  className="small DM Sans bg-white"
                                  style={{
                                    // fontSize: "16px",
                                    fontWeight: "700",
                                    color: "#064055",
                                  }}
                                >
                                  {price}
                                </option>
                              )
                            )}
                          </select>
                        </span>
                        <span
                          className="small DM Sans bg-white"
                          style={{
                            // fontSize: 16,
                            fontWeight: "700",
                            color: "#064055",
                          }}
                        >/ Person
                        </span>
                      </p>
                      <Button
                        style={{
                          backgroundColor: "#EA3560",
                          fontWeight: "700",
                          fontSize: "13px",
                          borderRadius: "25px",
                          borderWidth: 0,
                        }}
                        onClick={() => {
                          dispatch({
                            type: SET_TOUR_PACKAGE_ID,
                            payload: tour.packageId,
                          });
                          history.push(`/tour-detail:${tour.packageId}`);
                        }}
                      >
                        View More
                        {/* <p>Screen Width: {screenWidth}px</p>
    <p>Is Small Device: {isSmallDevice ? "Yes" : "No"}</p> */}
                      </Button>
                    </div>
                  </Col>
                </Card.Body>


              </Card>
            </Col>
          );
        })}
      </div>

      {/* View More Button */}
      {visibleTours < tourlist.length && (
        <Button
          className="align-self-center mt-4"
          style={{
            backgroundColor: "#EA356000",
            fontWeight: "700",
            fontSize: 18,
            borderRadius: "33px",
            padding: "10px 30px",
            borderColor: "#EA3560",
            width: "169px"
          }}
          onClick={handleViewMore}
        >
          <span
          className="DM Sans bg-white"
          style={{
            fontWeight: "500",
            color: "#064055",
          }}
          >
          View More
          </span>
        </Button>
      )}
    </Container>

    // <Container
    //   fluid
    //   className={`d-flex flex-column ${styles.mobileContainer}`}
    //   style={{
    //     marginTop: 40,
    //     marginBottom: 40,
    //   }}
    // >
    //   <h2
    //     className="bold d-flex justify-content-center align-items-center"
    //     style={{
    //       fontSize: 45,
    //       fontWeight: "400",
    //       marginTop: 20,
    //       marginBottom: 40,
    //       color: "#686868",
    //       textAlign: "center",
    //       fontFamily: "Poppins, sans-serif",
    //       position: "relative",
    //     }}
    //   >
    //     <span onClick={() => history.push("/explore")}>Lyamii Tours</span>
    //     <TbWorldSearch onClick={() => history.push("/explore")} className="top-0 align-items-center" />
    //     <span
    //       style={{
    //         display: "block",
    //         width: 90,
    //         height: 6,
    //         backgroundColor: "#75abdc",
    //         position: "absolute",
    //         bottom: -10,
    //         left: "50%",
    //         transform: "translateX(-50%)",
    //         borderRadius: "0px 0px 5px 5px",
    //         opacity: 0.7,
    //       }}
    //     />
    //   </h2>

    //   <div className="p-4 align-self-center w-100">
    //     <Row className="d-flex justify-content-center">
    //       {tourlist.slice(0, visibleTours).map((tour, index: number) => (
    //         <Col
    //           md={6}
    //           lg={4}
    //           sm={12}
    //           className="d-flex justify-content-center position-relative mb-4"
    //           key={index}
    //         >
    //           <Card
    //             className={`${styles.carouselCard}`}
    //             style={{
    //               width: "100%",
    //               maxWidth: "320px",
    //               margin: "0 auto",
    //               minWidth: "100%",
    //             }}
    //           >
    //             <Card.Img
    //               style={{ minHeight: "290px" }}
    //               variant="top"
    //               src={
    //                 tour?.imageUri?.length > 0 && tour.imageUri[0] !== ""
    //                   ? `https://drive.google.com/thumbnail?sz=w2000&id=${tour.imageUri[0]}`
    //                   : "https://drive.google.com/thumbnail?sz=w2000&id=1j8giF6uvrDsI-yfMYZFWxdBGe0wirl6w"
    //               }
    //               alt={tour.title}
    //               className={styles.cardImage}
    //             />
    //             <Card.Body className={`align-items-between d-grid ${styles.cardTextOverlay}`}>
    //               <div className="d-flex w-100 align-items-center justify-content-between align-content-between">
    //                 <Card.Title
    //                   className="position-absolute bg-white p-0 m-0"
    //                   style={{
    //                     fontSize: 25,
    //                     fontWeight: "700",
    //                     zIndex: "1",
    //                     color: "#75abdc",
    //                   }}
    //                 >
    //                   {tour.title}
    //                 </Card.Title>
    //                 <Card.Subtitle
    //                   style={{
    //                     fontSize: 13,
    //                     fontWeight: "400",
    //                     color: "#75abdc",
    //                     border: "0.2px solid #75abdc",
    //                     padding: "2px 5px",
    //                     borderRadius: "3px",
    //                     textAlign: "center",
    //                   }}
    //                 >
    //                   {tour.noOfDays} Days
    //                 </Card.Subtitle>
    //               </div>
    //               <div className="pt-2">
    //                 <Row className="list-unstyled">
    //                   {tour.destinations.slice(0, 4).map((destination, index) => (
    //                     <li key={index} className="col px-0 mx-0">
    //                       <span style={{ color: "#75abdc" }}>•</span> {destination}
    //                     </li>
    //                   ))}
    //                   {tour.destinations.length > 4 && (
    //                     <span className="col">+{tour.destinations.length - 4}</span>
    //                   )}
    //                 </Row>
    //               </div>
    //               <div className="d-flex justify-content-between align-items-center">
    //                 <p style={{ fontSize: 16, fontWeight: "700", color: "#05A1A5" }}>
    //                   {tour.packagePrice[0]} EUR / Person
    //                 </p>
    //                 <Button
    //                   style={{
    //                     backgroundColor: "#75abdc",
    //                     fontWeight: "700",
    //                     fontSize: 15,
    //                     borderRadius: 0,
    //                     borderWidth: 0,
    //                   }}
    //                   onClick={() => {
    //                     dispatch({
    //                       type: SET_TOUR_PACKAGE_ID,
    //                       payload: tour.packageId,
    //                     });
    //                     history.push(`/tour-detail:${tour.packageId}`);
    //                   }}
    //                 >
    //                   View More
    //                 </Button>
    //               </div>
    //             </Card.Body>
    //           </Card>
    //         </Col>

    //         // <Col
    //         //   md={6}
    //         //   lg={4}
    //         //   sm={12}
    //         //   className="d-flex justify-content-center position-relative mb-4"
    //         //   key={index}
    //         // >
    //         //   <Card
    //         //     className={`${styles.customCard}`}
    //         //     style={{
    //         //       width: "100%",
    //         //       maxWidth: "320px",
    //         //       borderRadius: "15px", // Rounded corners
    //         //       boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
    //         //       overflow: "hidden", // Ensure content stays inside the rounded border
    //         //     }}
    //         //   >
    //         //     {/* Card Image Section */}
    //         //     <div style={{ position: "relative" }}>
    //         //       <Card.Img
    //         //         src={
    //         //           tour?.imageUri?.length > 0 && tour.imageUri[0] !== ""
    //         //             ? `https://drive.google.com/thumbnail?sz=w2000&id=${tour.imageUri[0]}`
    //         //             : "https://drive.google.com/thumbnail?sz=w2000&id=1j8giF6uvrDsI-yfMYZFWxdBGe0wirl6w"
    //         //         }
    //         //         alt={tour.title}
    //         //         className={styles.cardImage}
    //         //         style={{
    //         //           height: "200px",
    //         //           objectFit: "cover",
    //         //           borderTopLeftRadius: "15px",
    //         //           borderTopRightRadius: "15px",
    //         //         }}
    //         //       />
    //         //       {/* Text Overlay */}
    //         //       <div
    //         //         className="d-flex flex-column justify-content-end"
    //         //         style={{
    //         //           position: "absolute",
    //         //           bottom: 0,
    //         //           width: "100%",
    //         //           background: "linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent)",
    //         //           color: "white",
    //         //           padding: "10px",
    //         //         }}
    //         //       >
    //         //         <h5
    //         //           style={{
    //         //             margin: 0,
    //         //             fontWeight: "700",
    //         //             textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
    //         //           }}
    //         //         >
    //         //           {tour.title}
    //         //         </h5>
    //         //         <p
    //         //           style={{
    //         //             margin: 0,
    //         //             fontSize: "14px",
    //         //             fontWeight: "400",
    //         //           }}
    //         //         >
    //         //           {tour.noOfDays} Days
    //         //         </p>
    //         //       </div>
    //         //     </div>

    //         //     {/* Card Content Section */}
    //         //     <Card.Body style={{ padding: "15px" }}>
    //         //       <ul className="list-unstyled">
    //         //         {tour.destinations.slice(0, 3).map((destination, index) => (
    //         //           <li key={index} style={{ fontSize: "14px", color: "#333" }}>
    //         //             <span style={{ color: "#75abdc", fontWeight: "700" }}>•</span> {destination}
    //         //           </li>
    //         //         ))}
    //         //         {tour.destinations.length > 3 && (
    //         //           <li style={{ fontSize: "14px", fontWeight: "600", color: "#75abdc" }}>
    //         //             +{tour.destinations.length - 3} more
    //         //           </li>
    //         //         )}
    //         //       </ul>
    //         //     </Card.Body>

    //         //     {/* Card Footer with Price and Button */}
    //         //     <Card.Footer
    //         //       className="d-flex justify-content-between align-items-center"
    //         //       style={{
    //         //         backgroundColor: "white",
    //         //         padding: "10px 15px",
    //         //         borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    //         //       }}
    //         //     >
    //         //       <p style={{ margin: 0, fontSize: "16px", fontWeight: "700", color: "#05A1A5" }}>
    //         //         {tour.packagePrice[0]} EUR / Person
    //         //       </p>
    //         //       <Button
    //         //         style={{
    //         //           backgroundColor: "#75abdc",
    //         //           fontWeight: "600",
    //         //           fontSize: "14px",
    //         //           padding: "5px 15px",
    //         //           borderRadius: "30px",
    //         //           border: "none",
    //         //         }}
    //         //         onClick={() => {
    //         //           dispatch({
    //         //             type: SET_TOUR_PACKAGE_ID,
    //         //             payload: tour.packageId,
    //         //           });
    //         //           history.push(`/tour-detail:${tour.packageId}`);
    //         //         }}
    //         //       >
    //         //         View Details
    //         //       </Button>
    //         //     </Card.Footer>
    //         //   </Card>
    //         // </Col>


    //       ))}
    //     </Row>
    //   </div>

    //   {visibleTours < tourlist.length && (
    //     <Button
    //       className="align-self-center mt-4"
    //       style={{
    //         backgroundColor: "#75abdc",
    //         fontWeight: "700",
    //         fontSize: 18,
    //         borderRadius: 5,
    //         padding: "10px 30px",
    //       }}
    //       onClick={handleViewMore}
    //     >
    //       View More
    //     </Button>
    //   )}
    // </Container>
  );
};

export default Destinations;
