import React, { FC, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import style from "./MapAndEscape.module.scss";
import Globe from "../../common/globe/Globe";
import { IoIosPaperPlane } from "react-icons/io";
import { escapeRoute } from "../../common/enum/escapePlanRoute";

import About from "../../../Assets/aboutUs1-bg.png";
import "./MapAndEscape.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { SET_SELECTED_LOCATION } from "../../../state/actions/types/globeDataActionType";
import { useHistory } from "react-router-dom";
import left_arrow_image from '../../../Assets/arrow-left-circled.png';
import right_arrow_image from '../../../Assets/arrow-right-circle.png';
import { selectScreenSize } from "../../../state/selectors/selectScreenSize";

const MapAndEscape: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);
  const data = [
    "Domestic Flights",
    "Lounge Access",
    "Airport drop-pickup",
    "Currency Exchange ",
    "Travel Insurance",
    "Health Insurance",
    "UPI Wallet",
    "Accommodations",
    "Cuisines",
    "Transfers",
    "Guides",
    "Entry Tickets ",
    "Show Tickets",
    "Activities",
    "Round the clock Multilingual Support",
  ];
  const fetchTours = (location: string) => {
    dispatch({
      type: SET_SELECTED_LOCATION,
      payload: {
        city: location,
        id: "",
        value: "",
        coordinates: "",
        color: "",
      },
    });
    window.scroll(0, 0);
    history.push("/explore");
  };

  // const scrollLeft = () => {
  //   containerRef.current?.scrollBy({
  //     left: -100, // Adjust for smooth scroll
  //     behavior: "smooth",
  //   });
  // };

  // const scrollRight = () => {
  //   containerRef.current?.scrollBy({
  //     left: 100, // Adjust for smooth scroll
  //     behavior: "smooth",
  //   });
  // };
  
  const scrollToImage = (index: number): void => {
    if (containerRef.current) {
      const container = containerRef.current;
      const child = container.children[index] as HTMLDivElement;
      const containerWidth = container.offsetWidth;
      const childWidth = child.offsetWidth;

      const scrollLeft = child.offsetLeft - (containerWidth - childWidth) / 2; // Center the child
      container.scrollTo({ left: scrollLeft, behavior: "smooth" });
    }
  };

  const scrollLeft = (): void => {
    if (containerRef.current) {
      const container = containerRef.current;
      const childWidth = (container.children[0] as HTMLDivElement).offsetWidth + 40; // Include gap
      container.scrollBy({ left: -childWidth, behavior: "smooth" });
    }
  };

  const scrollRight = (): void => {
    if (containerRef.current) {
      const container = containerRef.current;
      const childWidth = (container.children[0] as HTMLDivElement).offsetWidth + 40; // Include gap
      container.scrollBy({ left: childWidth, behavior: "smooth" });
    }
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine styles based on screen width
  const isSmallDevice = screenWidth <= 650;
  const isTablet = screenWidth > 768 && screenWidth <= 1024;

  const countries = [
    {
      name: "Norway",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1UIVoIsZB1I6_2ku1ci6JwGDzmlwYd-8u",
    },
    {
      name: "India",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1a0ihfVPTywsq2S4GWYEaM_fg28kazDc1",
    },
    {
      name: "Egypt",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1iTIDnEGbTZLNEjaaIY9jCug__uFt0u-N",
    },
    {
      name: "Malaysia",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1f9xcXqnhZ5x3kfwxYStH71kyOYaScdmD",
    },
    {
      name: "Japan",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1QXyTFtnbOypR8gIhYhEgoLj11nfI6Mgg",
    },
    {
      name: "France",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1PIUQRnR6A7vT1TOWhKCNbVC7bUgeASGi",
    },
    {
      name: "Iceland",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1RGFoNcN9F3JmeiUZ4tYVVyRFwOmjebwL",
    },
    {
      name: "Norway",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1UIVoIsZB1I6_2ku1ci6JwGDzmlwYd-8u",
    },
    {
      name: "Egypt",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1iTIDnEGbTZLNEjaaIY9jCug__uFt0u-N",
    },
    {
      name: "Malaysia",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1f9xcXqnhZ5x3kfwxYStH71kyOYaScdmD",
    },
    {
      name: "Japan",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1QXyTFtnbOypR8gIhYhEgoLj11nfI6Mgg",
    },
    {
      name: "France",
      imgSrc:
        "https://drive.google.com/thumbnail?sz=w2000&id=1PIUQRnR6A7vT1TOWhKCNbVC7bUgeASGi",
    },
  ];

  return (
    <div
      style={{
        // padding: 20,
        textAlign: "center",
        backgroundSize: "cover",
        // backgroundImage: `url(${About})`,
      }}
    >
      <div className="about-us row"
      style={{
        padding: 20,
        textAlign: "center",
        backgroundSize: "cover",
        backgroundImage: `url(${About})`,
      }}
      >
        
        <h2
          style={{
            fontSize: 45,
            fontWeight: "400",
            marginBottom: 40,
            color: "#FFFFFF",
            textAlign: "center",
            position: "relative",
          }}
        >
          About Us
          <span
            style={{
              display: "block",
              width: 90,
              height: 6,
              backgroundColor: "#75abdc",
              position: "absolute",
              bottom: -10,
              left: "50%",
              transform: "translateX(-50%)",
              borderRadius: "0px 0px 5px 5px",
              opacity: 0.7,
            }}
          />
        </h2>
        <p
          className="fs-medium"
          style={{
            // fontSize: 20,
            fontWeight: "400",
            textAlign: "center",
            // marginInline: 60,
            color: "#FFFFFF",
          }}
        >
          Driven by a shared belief that travel has the power to broaden lifes,
          and is the key to self exploration, a group of passionate travel
          enthusiasts from India set out to develop Lyamii, a platform to
          simplify foreign tourism with pre-loaded vacation chains. Team Lyamii
          harnessed the power of technology and the love for exploration to
          create a complete architecture to benefit the passionate travellers
          worldwide. Currently Lyamii covers 50+ countries connecting more than
          300+ destinations around the globe. So far Lyamii has opened two
          office locations, 18 bootcamps and 64 helpdesks across India. Lyamii
          also has received the Startup India recognition from the Indian
          government, DIPP167414. Team Lyamii is composed of diverse
          individuals, from seasoned world travellers, tech heads to active
          customer support and friendly volunteers, we have assembled a
          collective of talents who bring unique perspectives and skills to the
          platform.
        </p>
        <p
          className="fs-medium"
          style={{
            // fontSize: 20,
            fontWeight: "400",
            textAlign: "center",
            // marginInline: 60,
            marginBottom: 60,
            marginTop: 40,
            color: "#FFFFFF",
          }}
        >
          Lyamii connects all dots of a foreign tour, from domestic flights to
          accommodations and from currency exchange to authentic cultural
          delights, our users enjoy seamless vacations away from home. With four
          wings and four houses our operations team is more than just a
          department; it's a commitment to excellence and is dedicated to a
          seamless experience for the user from the beginning to the end with
          precision and care. It's 2024, live more, choose your journey and
          discover new dimensions of life with Lyamii.
        </p>
      </div>
      {/* <div  style={{ display: "flex",
         overflowX: "auto",
         padding: "60px 0", 
         backgroundColor: "#F3EBDB", 
         gap: "10px",
         paddingLeft: "15px"
         
        }}>
        {countries.map((country, index) => (
          <div key={index} style={{ flex: "0 0 auto", margin: "0 10px" }}>
            <img
              loading="lazy"
              onClick={() => fetchTours(country.name)}
              src={country.imgSrc}
              alt={country.name}
              style={{
                width: 211,
                height: 137,
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
            <p
              onClick={() => fetchTours(country.name)}
              style={{
                marginTop: 5,
                fontSize: 20,
                fontWeight: "700",
                color: "#75abdc",
                textAlign: "center",
              }}
            >
              {country.name}
            </p>
          </div>
        ))}
      </div> */}

<div
      style={{
        position: "relative",
        backgroundColor: "#F3EBDB",
        padding: "40px 0",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: isSmallDevice? "100%" : "90%",
          marginLeft: !isSmallDevice? "5%" : ""
        }}
      >
        {/* Left Button */}
        
        <button
          onClick={scrollLeft}
          style={{
            backgroundColor: "#F3EBDB",
            color: "#fff",
            border: "none",
            borderRadius: "50%",
            width: 40,
            height: 40,
            cursor: "pointer",
            marginBottom: "30px",
            // marginLeft: "10px"
            marginRight: !isSmallDevice? "3%" : ""
          }}
        >
          <img
            src={left_arrow_image} // Add your image URL here
            alt="left-arrow"
            style={{
              width: "30px",  // Adjust size as needed
              height: "30px",
            }}
          />
        </button>

        {/* Slider Container */}
        <div
          ref={containerRef}

              className="slider-img"
          style={{
            display: "flex",
            gap: isSmallDevice ? "0px" : "40px",
            overflowX: "auto",
            scrollBehavior: "smooth",
            flexWrap: "nowrap",
            flex: 1,
            padding: "10px 0",
            // marginLeft:"20px",
            // marginRight:"20px"

          }}
        >
          {countries.map((country, index) => (
            <div

              className="slider-img slider-img-item"
              key={index}
              style={{
                flex: "0 0 auto",
                textAlign: "center",
                width: "200px",
              }}
            >
              <img
                loading="lazy"
                onClick={() => alert(`Selected: ${country.name}`)}
                src={country.imgSrc}
                alt={country.name}
                style={{
                  width: "80%",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: "15px",
                  cursor: "pointer",
                  // paddingLeft: "10px",
                  // paddingRight: "10px",
                }}
              />
              <p
                style={{
                  marginTop: 5,
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#75abdc",
                  cursor: "pointer",
                }}
              >
                {country.name}
              </p>
            </div>
          ))}
        </div>

        {/* Right Button */}
        <button
          onClick={scrollRight}
          style={{
            backgroundColor: "#F3EBDB",
            color: "#fff",
            border: "none",
            borderRadius: "50%",
            width: 40,
            height: 40,
            cursor: "pointer",
            marginBottom: "30px",
            // marginRight: "10px"
            marginLeft: !isSmallDevice? "3%" : ""
          }}
        >
          <img
            src={right_arrow_image} // Add your image URL here
            alt="left-arrow"
            style={{
              width: "30px",  // Adjust size as needed
              height: "30px",
            }}
          />
        </button>
      </div>

      {/* Inline Styles for Responsiveness */}
      {/* <style>
        {`
        @media (max-width: 768px) {
          div[ref='containerRef'] {
            justify-content: start;
          }

          img {
            height: 120px ;
          }

          div[style*="width: 200px"] {
            width: 150px !important;
          }
        }
        `}
      </style> */}
    </div>


    </div>
  );
};

export default MapAndEscape;
