import React, { FC, useEffect, useState } from "react";
import {
  Col,
  Navbar,
  Nav,
  Container,
  Form,
  Carousel,
  Button,
  Row,
} from "react-bootstrap";
import Destinations from "../destinations/Destinations";
import WhyLyamii from "../whyLyamii/WhyLyamii";
import MapAndEscape from "../mapAndEscape/MapAndEscape";
import { useHistory } from "react-router-dom";
import LifetimeJourney from "../lifetimeJourney/LifetimeJourney";
import BeyoundObvious from "../beyoundObvious/BeyoundObvious";
import BusinessClients from "../businessClients/BusinessClients";
import sky from "../../../Assets/sky.png";
import signin from "../../../Assets/signin.png";
import profileUpdate from "../../../Assets/profileUpdate.png";
import verification from "../../../Assets/verification.png";
import travellerId from "../../../Assets/travellerId.png";
import firstTour from "../../../Assets/firstTour.png";
import documentation from "../../../Assets/documentation.png";
import CustomerReviewSection from "../customerReview/CustomerReviewSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import home_page_image from '../../../Assets/lyami_home_page.png';
import escapePageImage from '../../../Assets/escape_page_image.png';
import before_footer_image from '../../../Assets/before-footer.png';
import why_lyami_header from '../../../Assets/whyLyami.png';
import why_lyami_bg from '../../../Assets/why-lyamii.png';
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { SET_SELECTED_LOCATION } from "../../../state/actions/types/globeDataActionType";
import { useDispatch } from "react-redux";
import {
  SET_NON_INDIAN_TOURS,
  SET_TOUR_DATA,
} from "../../../state/actions/types/tourDataActionType";
import { parseTourDataArray } from "../../../utils/parseTourData";
import { getNonIndianToursApi } from "../../../api/nonIndianTours/getNonIndianToursApi";
import { getPackageDetailsByCountryAndDaysApi } from "../../../api/packageByCountryAndDays/getPackageDetailsByCountryAndDaysApi";
import { reasons } from "../../common/enum/enum";

const Home: FC = () => {
  const [index, setIndex] = useState(0); // State to manage carousel index
  const [tourDetail, setTourDetail] = useState({
    destination: "",
    startDate: "",
  });
  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex); // Function to update selected index
  };
  const dispatch = useDispatch();
  const [flippedIndex, setFlippedIndex] = useState(-1);

  const handleCardHover = (index: number) => {
    setFlippedIndex(index);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const isMobile = windowWidth <= 480;
  const isTablet = windowWidth <= 768;

  const fetchTours = () => {
    dispatch({
      type: SET_SELECTED_LOCATION,
      payload: {
        city: tourDetail.destination,
        id: "",
        value: "",
        coordinates: "",
        color: "",
      },
    });
    window.scroll(0, 0);
    history.push("/explore");
  };
  const handleCardLeave = () => {
    setFlippedIndex(-1);
  };
  const fetchTourData = async () => {
    window.scrollTo(0, 0);
    const response = await getPackageDetailsByCountryAndDaysApi(0, 1);
    if (response.status === 200) {
      dispatch({
        type: SET_TOUR_DATA,
        payload: parseTourDataArray(response.data),
      });
    }
    const res = await getNonIndianToursApi();
    if (response.status === 200) {
      dispatch({
        type: SET_NON_INDIAN_TOURS,
        payload: parseTourDataArray(res?.data),
      });
    }
  };
  useEffect(() => {
    fetchTourData();
  }, []);

  const history = useHistory();

  const slides = [
      {
        id: 1,
        title: "Sign In",
        description:
          "Use your preferred virtual id to Sign up and Log into your profile on Lyamii.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jXzdnKb2wyyd2VQI1dqmpsP66xvyTc1W",
        url: "/login",
      },
      {
        id: 2,
        title: "Profile Update",
        description:
          "Edit and update your profile in the My Profile section.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jY813eDAowz63_r0ezpjDO84B0s-K2Yp",
        url: "/profile/personalData",
      },
      {
        id: 3,
        title: "First Tour",
        description:
          "Select your first Lyamii vacation and proceed with the book now option.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jaLTfXpjBo5etObenG7XdO5reNkmJYxg",
        url: "/explore",
      },
      {
        id: 4,
        title: "Verification",
        description:
          "Verify your profile with your passport to generate the Traveller ID.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jZ8Vcb3-UrzpXFVn53HY39AWC8p0-WNR",
        url: "/passportRegistration",
      },
      {
        id: 5,
        title: "Traveller ID",
        description: "Receive your Traveller ID in the User Dashboard.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1ja0YAdA4RrSoXsdyrYOfGvo7mHd6Vii1",
        url: "",
      },
      {
        id: 6,
        title: "Documentation",
        description:
          "Complete the documentation process for insurance papers and reservations.",
        img: documentation,
        url: "/profile/documentations",
      },
      {
        id: 7,
        title: "Arrival",
        description:
          "Arrive at the destination and meet your assigned Lyamii volunteer at the airport",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jcDFQemjeh6RCFm1JnznN-2NConKEAh4",
        url: "/profile/documentations",
      },
      {
        id: 8,
        title: "Transaction",
        description:
          "Meet our representative at the common space of your day one accommodation and complete transaction and currency exchange seamlessly.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jeAD3DQ_lxFEYSioVAA6Od8rJXnMh3bh",
        url: "/profile/documentations",
      },
      {
        id: 9,
        title: "Tour ID",
        description:
          "Receive your activated Tour ID that connects all the details of your vacation.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jeEFqh44Uw3ZJ6ahNIYlqEG6oAPcuav_",
        url: "/profile/documentations",
      },
      {
        id: 10,
        title: "Ongoing Tour",
        description:
          "Enjoy perfectly crafted preloaded Lyamii vacation chains in foreign countries.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jgs36a5jysnk8RknsKEZrc8fL0W8NfLf",
        url: "/profile/documentations",
      },
      {
        id: 11,
        title: "Departure",
        description:
          "Return to your home with a suitcase full of memories.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jj1q9biMYZZ-TXIbU8BbhurIqDkRPoiL",
        url: "/profile/documentations",
      },
      {
        id: 12,
        title: "Journal",
        description:
          "Receive your customised Tour Journal and cherish the everlasting moments of the vacation.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jko6sxJ1iR2NK-1xhFTac76vZ1dwcZKH",
        url: "/profile/documentations",
      },
      {
        id: 13,
        title: "Souvenir",
        description:
          "Get your souvenir items (ordered on Lyamii during the ongoing tour) delivered at your doorstep.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jmdpZ_6CQn59Y6gjRH_BdfJeXVFGv84F",
        url: "/profile/documentations",
      },
      {
        id: 14,
        title: "Second Tour",
        description:
          "With your already verified traveller ID book or buy your next vacation directly from the platform.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jaLTfXpjBo5etObenG7XdO5reNkmJYxg",
        url: "/profile/documentations",
      },

      {
        id: 15,
        title: "Arrival",
        description:
          "Arrive at the destination and meet your assigned Lyamii volunteer at the airport",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jcDFQemjeh6RCFm1JnznN-2NConKEAh4",
        url: "/profile/documentations",
      },
      {
        id: 16,
        title: "Transaction",
        description:
          "Meet our representative at the common space of your day one accommodation and complete transaction and currency exchange seamlessly.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jeAD3DQ_lxFEYSioVAA6Od8rJXnMh3bh",
        url: "/profile/documentations",
      },
      {
        id: 17,
        title: "Tour ID",
        description:
          "Receive your activated Tour ID that connects all the details of your vacation.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jeEFqh44Uw3ZJ6ahNIYlqEG6oAPcuav_",
        url: "/profile/documentations",
      },
      {
        id: 18,
        title: "Ongoing Tour",
        description:
          "Complete the documentation process for insurance papers and reservations.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jgs36a5jysnk8RknsKEZrc8fL0W8NfLf",
        url: "/profile/documentations",
      },
      {
        id: 19,
        title: "Departure",
        description:
          "Complete the documentation process for insurance papers and reservations.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jj1q9biMYZZ-TXIbU8BbhurIqDkRPoiL",
        url: "/profile/documentations",
      },
      {
        id: 20,
        title: "Journal",
        description:
          "Complete the documentation process for insurance papers and reservations.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jko6sxJ1iR2NK-1xhFTac76vZ1dwcZKH",
        url: "/profile/documentations",
      },
      {
        id: 21,
        title: "Souvenir",
        description:
          "Complete the documentation process for insurance papers and reservations.",
        img: "https://drive.google.com/thumbnail?sz=w2000&id=1jmdpZ_6CQn59Y6gjRH_BdfJeXVFGv84F",
        url: "/profile/documentations",
      },
    ];

  return (
    <section
    //  style={{ position: "relative" }}
     >
      {/* Top Navigation Bar */}
      {/* <Navbar expand="lg"fixed="top"style={{ backgroundColor: "#1C1C1C"}}>
        <Container>
          <div style={{ display: "flex", justifyContent: "flex-start"}}>
            <Navbar.Brand href="#home"className="text-white ms-0">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="ms-2"
                color="#75abdc"
              />{""}
              Connect.helpdesk@lyamii.com
            </Navbar.Brand>
            <Nav
              className="ms-auto"
              style={{ borderLeft: "1px solid #75abdc", paddingLeft: "10px"}}
            >
              <Nav.Link href="#home"className="text-white">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="ms-2"
                  color="#75abdc"
                />{""}
                Connect.business@lyamii.com
              </Nav.Link>
              <Nav.Link
                href="#"
                className="text-white"
                style={{ borderLeft: "1px solid #75abdc", paddingLeft: "10px"}}
              >
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="me-1"
                  color="#75abdc"
                />{""}
                Indra Nagar, Banglore
              </Nav.Link>
            </Nav>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end"}}>
            <Nav>
              <Nav.Link href="#"className="text-white">
                <FontAwesomeIcon icon={faTwitter} className="ms-0"/>
              </Nav.Link>
              <Nav.Link href="#"className="text-white">
                <FontAwesomeIcon icon={faInstagram} className="ms-0"/>
              </Nav.Link>
              <Nav.Link href="#"className="text-white">
                <FontAwesomeIcon icon={faFacebook} className="ms-0"/>
              </Nav.Link>
              <Nav.Link
                href="#"
                className="text-white"
                style={{ backgroundColor: "#75abdc"}}
              >
                Log In
              </Nav.Link>
            </Nav>
          </div>
        </Container>
      </Navbar> */}
      {/* Movable Navbar */}
      {/* <Navbar
        expand="lg"
        style={{
          position: "sticky",
          top: "50px",
          zIndex: "1020",
          backgroundColor: "rgba(28, 28, 28, 0.8)",
          width: "100%",
        }}
      >
        <Container fluid>
          <Navbar.Brand href="#home"style={{ marginRight: "auto"}}>
            <img
              src="https://lyamii.com/static/media/logo.1f8ab1a3952e8db84941.png"
              style={{
                width: 50,
                height: 50,
              }}
            />
            <img
              src="https://lyamii.com/static/media/lyamii.8da8f0171bd8461ee18f.png"
              style={{
                width: 70,
                height: 30,
                marginLeft: 10,
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto"style={{ gap: "1rem"}}>
              <Nav.Link
                href="#home"
                style={{
                  marginRight: "1rem",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#packages"
                style={{
                  marginRight: "1rem",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                Packages
              </Nav.Link>
              <Nav.Link
                href="#contact"
                style={{
                  marginRight: "1rem",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                Contact
              </Nav.Link>
              <Nav.Link
                href="#faq"
                style={{
                  marginRight: "1rem",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                FAQ
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      {/* Main Section */}
      <img
        className="d-block w-100 "
        // src="https://drive.google.com/thumbnail?sz=w2000&id=1k09rkJVx55oQIWijilw15vEuY5QRz0QU"
        src= {home_page_image}
        alt="Background"
        loading="lazy"
        style={{ 
          height: "86vh",
          objectFit: "cover",
          zIndex:0
        }}
      />
      <section
        style={{
          position: "absolute",
          top: "40%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)", // Perfect centering
          zIndex: 5,
          textAlign: "center", // Center text alignment
          width: "100%",
          maxWidth: "800px", // Limit the width for responsiveness
          padding: "0 16px", // Add padding for smaller screens
        }}
      >
        <h2 className="text-center pb-5 textShadow text-shadow-dark text-white"
         style={{
          fontFamily: "DM Sans, sans-serif",
          fontSize: "3rem", // Smaller size for responsiveness
          fontWeight: 800,
          lineHeight: "1.2", // Line height adjusted for better spacing
          color: "white", // Ensure visibility on the background
          marginBottom: "20px",
        }}
        >
          Dream. Discover. Live.
        </h2>
        {/* <Form className="d-flex justify-content-around mt-5">
          <div
            className="mx-2 m-0 position-absolute bottom-0 start-0"
            style={{
              display: "flex",
              alignItems: "center",
              // backgroundColor: "white",
              borderRadius: "5px",
              // padding: "0 10px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              // marginBottom: 20,
            }}
          > */}
            {/* <Button
              onClick={() => {
                history.push("/passportRegistration");
              }}
              className="p-2 m-0 small"
              style={{
                backgroundColor: "#75abdc",
                fontWeight: "bold",
                height: 50,
                border: "none",
                boxShadow: "none",
                // marginLeft: "10px",
              }}
            >
              Verify Passport
            </Button> */}
          {/* </div>
          <div
            className="mx-2 m-0 position-absolute col-lg-4 col-8 bottom-0 end-0"
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: "5px",
              // padding: "0 10px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              marginBottom: 20,
            }}
          >
            <Form.Control
              type="search"
              value={tourDetail.destination}
              className="border border-0"
              placeholder="Where to?"
              aria-label="Search"
              onChange={(e: any) => {
                setTourDetail({
                  ...tourDetail,
                  destination: e.target.value,
                });
              }}
              style={{
                height: 50,
                // border: "none",
                boxShadow: "none",
              }}
            /> */}
            {/* <div
              style={{
                width: "1px",
                height: "60px",
                backgroundColor: "#ddd",
                margin: "0 10px",
              }}
            /> */}
            {/* <Form.Control
              type="date"
              className="border border-0"
              placeholder="Month"
              aria-label="Month"
              style={{
                width: 150,
                height: 60,
                // border: "none",
                boxShadow: "none",
              }}
            /> */}
            {/* <Button
              onClick={() => {
                fetchTours();
              }}
              className="small text-nowrap"
              style={{
                backgroundColor: "#75abdc",
                fontWeight: "bold",
                height: 50,
                border: "none",
                boxShadow: "none",
                marginLeft: "10px",
              }}
            >
              Find Now
            </Button>
          </div>
        </Form> */}
        <Form className="d-flex justify-content-center">
      <div
        className="d-flex align-items-center"
        style={{
          backgroundColor: "white",
          borderRadius: "40px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "400px", // Limit the width of the search bar
          padding: "5px",
          overflow: "hidden",
          position: "relative"
        }}
      >
        <FontAwesomeIcon
          icon={faArrowRight}
          className="position-absolute"
          // style={{
          //   top: "88%",
          //   right: "28%", // Position at the right side
          //   transform: "translateY(-50%)",
          //   fontSize: "1.2rem",
          //   color: "#6c757d", // Adjust icon color
          //   cursor: "pointer", // Add a pointer for interactivity
          // }}
          style={{
            top: "50%",
            right: "15px", // Adjusted for proper alignment inside the box
            transform: "translateY(-50%)",
            fontSize: "1.5rem", // Increased icon size for better visibility
            color: "#6c757d",
            cursor: "pointer",
          }}
          onClick={() => fetchTours()} // Optional: Attach a click handler
        />
        {/* Input Field */}
        <Form.Control
          type="search"
          value={tourDetail.destination}
          className="border border-0"
          placeholder="Where To ?"
          aria-label="Search"
          onChange={(e: any) => {
            setTourDetail({
              ...tourDetail,
              destination: e.target.value,
            });
          }}
          style={{
            flex: 1, // Make it fill the container
            height: "35px",
            border: "none",
            boxShadow: "none",
            borderRadius: "40px",
            // padding: "0 10px",
            padding: "0 45px 0 15px"
          }}
        />
        
        {/* Submit Button */}
        {/* <Button
          onClick={() => {
            fetchTours();
          }}
          style={{
            backgroundColor: "#75abdc",
            fontWeight: "bold",
            height: "50px",
            border: "none",
            boxShadow: "none",
            padding: "0 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>Find Now</span> */}
        {/* </Button> */}
      </div>
      </Form>
      </section>
      <Destinations />
      {/* steps of progress */}
      <div 
        style={{ 
          // padding: "50px 0",
          textAlign: "center",
          backgroundImage: `url(${escapePageImage})`, // Replace with your background image URL
          backgroundSize: "cover", // Makes the image cover the whole div
          backgroundPosition: "center", // Centers the image
          backgroundRepeat: "no-repeat", // Prevents repeating
          position: "relative", // Ensures child elements are properly positioned
          zIndex: 1,
          overflow: "hidden", // Hide overflow for a clean layout
          whiteSpace: "nowrap",
        }}>
        {/* Header Text */}
        {/* <p
          style={{
            fontSize: 28,
            fontWeight: "400",
            color: "#75abdc",
            marginBottom: 10,
            fontFamily: "cursive",
          }}
        >
          How It Works
        </p> */}
        <h2
          className="pt-5 bold"
          style={{
            fontSize: 45,
            fontWeight: "400",
            marginBottom: 40,
            color: "#686868",
            textAlign: "center",
            position: "relative",
          }}
        >
          <img
            className="col-md-3 col-sm-4 col-lg-2 col-sx-2 col-6"
            src={require("../../../Assets/escape.png")}
          />
        </h2>
        {/* Horizontal Scrollable Section */}
        {/* <div
          style={{
            display: "flex",
            overflowX: "auto",
            padding: "0 20px",
            alignItems: "center",
            gap: 20,
          }}
        >
          {[
            {
              id: 1,
              title: "Sign In",
              description:
                "Use your preferred virtual id to Sign up and Log into your profile on Lyamii.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jXzdnKb2wyyd2VQI1dqmpsP66xvyTc1W",
              url: "/login",
            },
            {
              id: 2,
              title: "Profile Update",
              description:
                "Edit and update your profile in the My Profile section.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jY813eDAowz63_r0ezpjDO84B0s-K2Yp",
              url: "/profile/personalData",
            },
            {
              id: 3,
              title: "First Tour",
              description:
                "Select your first Lyamii vacation and proceed with the book now option.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jaLTfXpjBo5etObenG7XdO5reNkmJYxg",
              url: "/explore",
            },
            {
              id: 4,
              title: "Verification",
              description:
                "Verify your profile with your passport to generate the Traveller ID.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jZ8Vcb3-UrzpXFVn53HY39AWC8p0-WNR",
              url: "/passportRegistration",
            },
            {
              id: 5,
              title: "Traveller ID",
              description: "Receive your Traveller ID in the User Dashboard.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1ja0YAdA4RrSoXsdyrYOfGvo7mHd6Vii1",
              url: "",
            },
            {
              id: 6,
              title: "Documentation",
              description:
                "Complete the documentation process for insurance papers and reservations.",
              img: documentation,
              url: "/profile/documentations",
            },
            {
              id: 7,
              title: "Arrival",
              description:
                "Arrive at the destination and meet your assigned Lyamii volunteer at the airport",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jcDFQemjeh6RCFm1JnznN-2NConKEAh4",
              url: "/profile/documentations",
            },
            {
              id: 8,
              title: "Transaction",
              description:
                "Meet our representative at the common space of your day one accommodation and complete transaction and currency exchange seamlessly.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jeAD3DQ_lxFEYSioVAA6Od8rJXnMh3bh",
              url: "/profile/documentations",
            },
            {
              id: 9,
              title: "Tour ID",
              description:
                "Receive your activated Tour ID that connects all the details of your vacation.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jeEFqh44Uw3ZJ6ahNIYlqEG6oAPcuav_",
              url: "/profile/documentations",
            },
            {
              id: 10,
              title: "Ongoing Tour",
              description:
                "Enjoy perfectly crafted preloaded Lyamii vacation chains in foreign countries.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jgs36a5jysnk8RknsKEZrc8fL0W8NfLf",
              url: "/profile/documentations",
            },
            {
              id: 11,
              title: "Departure",
              description:
                "Return to your home with a suitcase full of memories.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jj1q9biMYZZ-TXIbU8BbhurIqDkRPoiL",
              url: "/profile/documentations",
            },
            {
              id: 12,
              title: "Journal",
              description:
                "Receive your customised Tour Journal and cherish the everlasting moments of the vacation.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jko6sxJ1iR2NK-1xhFTac76vZ1dwcZKH",
              url: "/profile/documentations",
            },
            {
              id: 13,
              title: "Souvenir",
              description:
                "Get your souvenir items (ordered on Lyamii during the ongoing tour) delivered at your doorstep.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jmdpZ_6CQn59Y6gjRH_BdfJeXVFGv84F",
              url: "/profile/documentations",
            },
            {
              id: 14,
              title: "Second Tour",
              description:
                "With your already verified traveller ID book or buy your next vacation directly from the platform.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jaLTfXpjBo5etObenG7XdO5reNkmJYxg",
              url: "/profile/documentations",
            },

            {
              id: 15,
              title: "Arrival",
              description:
                "Arrive at the destination and meet your assigned Lyamii volunteer at the airport",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jcDFQemjeh6RCFm1JnznN-2NConKEAh4",
              url: "/profile/documentations",
            },
            {
              id: 16,
              title: "Transaction",
              description:
                "Meet our representative at the common space of your day one accommodation and complete transaction and currency exchange seamlessly.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jeAD3DQ_lxFEYSioVAA6Od8rJXnMh3bh",
              url: "/profile/documentations",
            },
            {
              id: 17,
              title: "Tour ID",
              description:
                "Receive your activated Tour ID that connects all the details of your vacation.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jeEFqh44Uw3ZJ6ahNIYlqEG6oAPcuav_",
              url: "/profile/documentations",
            },
            {
              id: 18,
              title: "Ongoing Tour",
              description:
                "Complete the documentation process for insurance papers and reservations.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jgs36a5jysnk8RknsKEZrc8fL0W8NfLf",
              url: "/profile/documentations",
            },
            {
              id: 19,
              title: "Departure",
              description:
                "Complete the documentation process for insurance papers and reservations.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jj1q9biMYZZ-TXIbU8BbhurIqDkRPoiL",
              url: "/profile/documentations",
            },
            {
              id: 20,
              title: "Journal",
              description:
                "Complete the documentation process for insurance papers and reservations.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jko6sxJ1iR2NK-1xhFTac76vZ1dwcZKH",
              url: "/profile/documentations",
            },
            {
              id: 21,
              title: "Souvenir",
              description:
                "Complete the documentation process for insurance papers and reservations.",
              img: "https://drive.google.com/thumbnail?sz=w2000&id=1jmdpZ_6CQn59Y6gjRH_BdfJeXVFGv84F",
              url: "/profile/documentations",
            },
          ].map((step, index, arr) => (
            <React.Fragment key={step.id}>
              <div
                style={{
                  minWidth: 300,
                  textAlign: "center",
                  position: "relative",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    width: 250,
                    height: 250,
                    borderRadius: "50%",
                    overflow: "hidden",
                    margin: "0 auto 20px",
                    position: "relative",
                    border: "2px dotted #ccc",
                    borderColor: "#75abdc",
                    padding: 20,
                    boxSizing: "border-box",
                  }}
                >
                  <img
                    onClick={() => history.push(step.url)}
                    src={step.img}
                    loading="lazy"
                    alt={`Step ${step.id}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 50,
                      right: 10,
                      backgroundColor: "#75abdc",
                      width: 50,
                      height: 50,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      fontWeight: "700",
                      border: "4px solid white",
                    }}
                  >
                    {`${step.id < 10 ? "0" : ""}${step.id}`}
                  </div>
                </div>
                <h3
                  style={{
                    margin: "10px 0",
                    fontSize: 25,
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  {step.title}
                </h3>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {step.description}
                </p>
              </div>

              {index < arr.length - 1 && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg
                    width="100"
                    height="50"
                    viewBox="0 0 100 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 25C30 0 70 0 100 25"
                      stroke="#ccc"
                      strokeWidth="2"
                      fill="none"
                      markerEnd="url(#arrowhead)"
                    />
                    <defs>
                      <marker
                        id="arrowhead"
                        markerWidth="10"
                        markerHeight="7"
                        refX="10"
                        refY="3.5"
                        orient="auto"
                      >
                        <polygon points="0 0, 10 3.5, 0 7" fill="black" />
                      </marker>
                    </defs>
                  </svg>
                </div>
              )}
            </React.Fragment>
          ))}
        </div> */}

<div
    style={{
      display: "flex",
      gap: "50px",
      scrollSnapType: "x mandatory",
      overflowX: "auto", // Enables horizontal scrolling
      padding: "50px", // Space around the carousel
    }}
    className="slides-container"
  >
    {slides.map((step, index) => (
      <div
        key={step.id}
        style={{
          minWidth: "300px", // Fix width for the card
          maxWidth: "300px", // Prevents shrinking beyond this size
          flexShrink: 0, // Prevent the card from shrinking
          backgroundColor: "#ffffff",
          borderRadius: "15px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          // padding: "20px",
          display: "flex",
          flexDirection: index % 2 !== 0 ?"column" : "column-reverse" ,
          // justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          position: "relative",
          scrollSnapAlign: "center", // Ensure cards snap to the center
        }}
      >
        {/* Odd Card: Image above */}
        {index % 2 === 0 && (
          <div
            style={{
              width: "60%",
              height: "100%",
              borderRadius: "10px",
              overflow: "hidden",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "chocolate",
              marginBottom: "45%"
            }}
          >
            <img
              src={step.img}
              alt={`Step ${step.id}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: "flex",
                alignSelf: "center"
              }}
            />

              {/*round*/}
              <div
                style={{
                  position: "absolute",
                  top: "60%",
                  transform: "translate(-50%, -50%)",
                  left: "50%",
                  backgroundColor: "#EA3560",
                  color: "#ffffff",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "16px",
                  zIndex: 1,
                }}
              >
              <span
            className="Hurricane">
              {step.id}</span>
            </div>

            <div
            style={{
              textAlign: "center",
              borderRadius: "0 0 15px 15px",
              padding: "15px",
              position: "absolute",
              top: "60%",
              overflow: "hidden",
              height: "40%",
              background: "#f5f3f3"
            }}
            >
          <h3
            style={{
              margin: "10px 0",
              fontSize: "20px",
              fontWeight: "700",
              color: "#333333",
              textAlign: "left",
    // color: "rgb(51, 51, 51)"
            }}
          >
            {step.title}
          </h3>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#666666",
              textWrap: "initial",
              textAlign: "left",
    // color: rgb(102, 102, 102);
            }}
          >
            {step.description}
          </p>
        </div>
          </div>

          
        )}

        {/* Numbering Circle */}
        {/* <div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
            backgroundColor: "#EA3560",
            color: "#ffffff",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "16px",
            zIndex: 1,
          }}
        >
          <span
        className="Hurricane">
          {step.id}</span>
        </div> */}

        {/* Title and Description */}
        {/* <div
          style={{
            textAlign: "center",
            borderRadius: "0 0 15px 15px",
    padding: "15px",
    position: "absolute",
    top: "60%",
    overflow: "hidden",
    height: "40%",
    background: "#f5f3f3"
          }}
        >
          <h3
            style={{
              margin: "10px 0",
              fontSize: "20px",
              fontWeight: "700",
              color: "#333333",
              textAlign: "left",
    // color: "rgb(51, 51, 51)"
            }}
          >
            {step.title}
          </h3>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#666666",
              textWrap: "initial",
              textAlign: "left",
    // color: rgb(102, 102, 102);
            }}
          >
            {step.description}
          </p>
        </div> */}

        {/* Even Card: Image below */}
        {index % 2 === 1 && (
          
          // <div
          //   style={{
          //     width: "150px",
          //     height: "150px",
          //     borderRadius: "10px",
          //     overflow: "hidden",
          //     zIndex: 1,
          //     display: "flex",
          //     alignItems: "center",
          //     justifyContent: "center"
          //   }}
          // >

          // <div
          //   style={{
          //     textAlign: "center",
          //     borderRadius: "15px 15px 0 0",
          //     padding: "15px",
          //     position: "absolute",
          //     bottom: "60%",
          //     overflow: "hidden",
          //     height: "40%",
          //     background: "#f5f3f3"
          //           }}
          //   >
          //   <h3
          //     style={{
          //       margin: "10px 0",
          //       fontSize: "20px",
          //       fontWeight: "700",
          //       color: "#333333",
          //       textAlign: "left",
          //     // color: "rgb(51, 51, 51)"
          //     }}
          //   >
          //     {step.title}
          //   </h3>
          //   <p
          //     style={{
          //       fontSize: "14px",
          //       fontWeight: "400",
          //       color: "#666666",
          //       textWrap: "initial",
          //       textAlign: "left",
          //     // color: rgb(102, 102, 102);
          //     }}
          //   >
          //     {step.description}
          //   </p>
          // </div>

          //     {/*round*/}
          //     <div
          //       style={{
          //         position: "absolute",
          //         bottom: "48%",
          //         transform: "translate(-50%, -50%)",
          //         left: "50%",
          //         backgroundColor: "#EA3560",
          //         color: "#ffffff",
          //         width: "40px",
          //         height: "40px",
          //         borderRadius: "50%",
          //         display: "flex",
          //         alignItems: "center",
          //         justifyContent: "center",
          //         fontWeight: "bold",
          //         fontSize: "16px",
          //         zIndex: 1,
          //       }}
          //     >
          //     <span
          //   className="Hurricane">
          //     {step.id}</span>
          //   </div>

          //   <img
          //     src={step.img}
          //     alt={`Step ${step.id}`}
          //     style={{
          //       width: "100%",
          //       height: "100%",
          //       objectFit: "cover",
          //     }}
          //   />
          // </div>

          <div
            style={{
              width: "60%",
              height: "100%",
              borderRadius: "10px",
              overflow: "hidden",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "chocolate",
              marginTop: "45%"
            }}
          >
            <img
              src={step.img}
              alt={`Step ${step.id}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: "flex",
                alignSelf: "center"
              }}
            />

              {/*round*/}
              <div
                style={{
                  position: "absolute",
                  bottom: "48%",
                  transform: "translate(-50%, -50%)",
                  left: "50%",
                  backgroundColor: "#EA3560",
                  color: "#ffffff",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "16px",
                  zIndex: 1,
                }}
              >
              <span
            className="Hurricane">
              {step.id}</span>
            </div>

            <div
            style={{
              textAlign: "center",
              borderRadius: "15px 15px 0 0",
              padding: "15px",
              position: "absolute",
              bottom: "60%",
              overflow: "hidden",
              height: "40%",
              background: "#f5f3f3"
            }}
            >
          <h3
            style={{
              margin: "10px 0",
              fontSize: "20px",
              fontWeight: "700",
              color: "#333333",
              textAlign: "left",
    // color: "rgb(51, 51, 51)"
            }}
          >
            {step.title}
          </h3>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#666666",
              textWrap: "initial",
              textAlign: "left",
    // color: rgb(102, 102, 102);
            }}
          >
            {step.description}
          </p>
        </div>
          </div>


        )}
      </div>
    ))}
  </div>


      </div>
      <LifetimeJourney />
      {/* why lyamii old*/}
      {/* <div style={{ position: "relative", padding: "50px 0" }}> */}
        {/* Background Image */}
        {/* <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${sky})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: -1,
            filter: "brightness(50%)", // Adjust brightness to ensure text is readable
          }}
        ></div> */}

        {/* Text with background */}
        {/* <p
          style={{
            fontSize: 45,
            fontWeight: "700",
            color: "#686868",
            textAlign: "center",
            position: "relative",
            zIndex: 1,
          }}
        >
          <img
            className="col-md-3 col-sm-4 col-lg-2 col-sx-2 col-6"
            src={require("../../../Assets/whyLyami.png")}
          />
        </p> */}

        {/* Horizontal cards */}

        {/* <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 30,
            marginTop: 50,
            position: "relative",
            zIndex: 1,
          }}
        >
          {reasons.map((card, index) => (
            <Col
              lg={3}
              md={6}
              sm={6}
              sx={6}
              key={index}
              onMouseEnter={() => handleCardHover(index)}
              onMouseLeave={handleCardLeave}
              style={{
                width: 300,
                height: 300, // Adjust height if needed
                perspective: "1000px", // Add perspective for 3D effect
                position: "relative",
                transformStyle: "preserve-3d",
                transition: "transform 0.6s",
                transform:
                  index === flippedIndex ? "rotateY(180deg)" : "rotateY(0deg)",
              }}
            > */}
              {/* Front side (image) */}
              {/* <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  backfaceVisibility: "hidden",
                  borderRadius: 8,
                  overflow: "hidden",
                }}
              >
                <img
                  src={card.image}
                  loading="lazy"
                  alt={card.title}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div> */}

              {/* Back side (text) */}
              {/* <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  backfaceVisibility: "hidden",
                  borderRadius: 8,
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "#ffffff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  transform: "rotateY(180deg)",
                  padding: 20,
                }}
              >
                <h3
                  style={{ fontSize: 25, fontWeight: "700", color: "#75abdc" }}
                >
                  {card.title}
                </h3>
                <p style={{ fontSize: 18, fontWeight: "400", lineHeight: 1.8 }}>
                  {card.description}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </div> */}
      {/* why lyamii end */}

      <div
      style={{
        position: "relative",
        padding: "50px 0",
        // backgroundImage: `url(${why_lyami_bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img
    src={why_lyami_bg} // Assuming the background image is the same as the img source
    alt="Background"
    style={{
      width: "100%",          // Ensure the image takes up the full width of the container
      height: "auto",         // Maintain aspect ratio
      objectFit: "cover",     // Cover the container without overflowing
      position: "absolute",   // Position the image to fill the container
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }}
  />
      {/* "Why Lyamii" Section */}
      <div
        style={{
          textAlign: "center",
          margin: "50px",
          zIndex:1,
          position: "relative", 
        }}
      >
        <img
          src={why_lyami_header}
          alt="Why Lyamii"
          style={{
            width: "200px", // Adjust as needed
            height: "auto",
          }}
        />
      </div>

      {/* Horizontal cards */}
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "30px",
          marginTop: "20px",
          zIndex:1
        }}
      >
      <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px',
        border: '5px solid #f2f2f2',
        borderRadius: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
        margin: '20px',
        zIndex: 1,
        width: isMobile ? '95%' : '75%',
        // maxWidth: '1200px',
        boxSizing: 'border-box',
        flexDirection: isMobile ? 'column' : 'row', // Stack vertically on small screens
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '25px',
          border: '1px solid #ccc',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
          zIndex: 1,
          // width: isTablet ? '100%' : '48%', // Full width on tablets and mobiles, 48% on larger screens
          // boxSizing: 'border-box',
          flexDirection: 'row',
          // marginBottom: isMobile ? '15px' : '0', // Adjust margin on small screens
          height: isMobile ? '200px' : '100%',
        }}
      >
        <div
          style={{
            flex: 1,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: isMobile ? '200px' : '100%', // Adjust image height on mobile
          }}
        >
          <img
            src={reasons[0].image}
            loading="lazy"
            alt={reasons[0].title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
        <div
          style={{
            flex: 2,
            padding: '0 20px',
          }}
        >
          <h2
            style={{
              fontSize: isMobile ? '1.2rem' : '1.5rem',
              color: '#2c3e50',
            }}
          >
            {reasons[0].title}
          </h2>
          <p
            style={{
              fontSize: isMobile ? '0.9rem' : '1rem',
              color: '#7f8c8d',
              lineHeight: '1.5',
            }}
          >
            {reasons[0].description}
          </p>
        </div>
      </div>
    </div>
    <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "15px",
          border: "5px solid #f2f2f2", /* Thick outer border */
          borderRadius: "15px", /* Rounded corners */
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
          margin: "20px",
          // backgroundColor: "#fff",
          zIndex:1,
          width: isMobile ? '95%' : '75%',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
          flexDirection: isMobile ? 'column' : 'row', // Stack vertically on small screens
          flexWrap: 'wrap'
          }}>
      <div className='sm:flex-direction-row-reverse' style={{
          display: "flex",
          alignItems: "center",
          padding: "25px",
          border: "1px solid #ccc",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          // margin: "20px",
          backgroundColor: "#fff",
          flexDirection: "row-reverse",
          zIndex:1,
          // width:"75%"
        }}>
      <div style={{
        flex: "1",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "100%"}}>
      <img
        src={reasons[1].image}
        loading="lazy"
        alt={reasons[1].title}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      </div>
      <div style={{
        flex: "2",
        padding: "0 20px"
      }}>
        <h2 style={{
          fontSize: "1.5rem",
          color: "#2c3e50"
        }}>{reasons[1].title}</h2>
        <p
        style={{
          fontSize: "1rem",
          color: "#7f8c8d",
          lineHeight: "1.5"}}
          >
          {reasons[1].description}
        </p>
      </div>
      </div>
    </div>
    <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "15px",
          border: "5px solid #f2f2f2", /* Thick outer border */
          borderRadius: "15px", /* Rounded corners */
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
          margin: "20px",
          // backgroundColor: "#fff",
          zIndex:1,
          width: isMobile ? '95%' : '75%',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
        flexDirection: isMobile ? 'column' : 'row', // Stack vertically on small screens
        flexWrap: 'wrap'
          }}>      
      <div 
          style={{
            display: "flex",
            alignItems: "center",
            padding: "25px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            // margin: "20px",
            backgroundColor: "#fff",
            zIndex:1,
            // width:"75%"
          }}>
        <div style={{
          flex: "1",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100%"
        }}>
        <img
          src={reasons[2].image}
          loading="lazy"
          alt={reasons[2].title}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        </div>
        <div style={{
          flex: "2",
          padding: "0 20px"
        }}>
          <h2 style={{
            fontSize: "1.5rem",
            color: "#2c3e50"
          }}>{reasons[2].title}</h2>
          <p style={{
            fontSize: "1rem",
            color: "#7f8c8d",
            lineHeight: "1.5"
          }}>
            {reasons[2].description}
          </p>
        </div>
      </div>
    </div>
    <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "15px",
          border: "5px solid #f2f2f2", /* Thick outer border */
          borderRadius: "15px", /* Rounded corners */
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
          margin: "20px",
          // backgroundColor: "#fff",
          zIndex:1,
          width: isMobile ? '95%' : '75%',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
        flexDirection: isMobile ? 'column' : 'row', // Stack vertically on small screens
        flexWrap: 'wrap'}}>
      <div className='sm:flex-direction-row-reverse' style={{
            display: "flex",
            alignItems: "center",
            padding: "25px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            // margin: "20px",
            backgroundColor: "#fff",
            flexDirection: "row-reverse",
            zIndex:1,
            // width:"75%"
          }}>
        <div style={{
          flex: "1",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100%"}}>
        <img
          src={reasons[3].image}
          loading="lazy"
          alt={reasons[3].title}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        </div>
        <div style={{
          flex: "2",
          padding: "0 20px"
        }}>
          <h2 style={{
            fontSize: "1.5rem",
            color: "#2c3e50"
          }}>{reasons[3].title}</h2>
          <p
          style={{
            fontSize: "1rem",
            color: "#7f8c8d",
            lineHeight: "1.5"}}
            >
            {reasons[3].description}
          </p>
        </div>
      </div>
    </div>        
      </Row>
    </div>

      {/* krna hai espe work */}
      {/* <BeyoundObvious /> */}
      {/* <WhyLyamii /> */}
      <CustomerReviewSection />
      {/* <Contact /> */}

      <MapAndEscape />
      <BusinessClients />
      <div
      style={{ 
        // display: "flex",
        overflowX: "auto",
        padding: "70px 0", 
        gap: "10px",
        // paddingLeft: "15px",
        backgroundImage: `url(${before_footer_image})`
        
       }}
      >
        
      </div>
    </section>
  );
};

export default Home;
