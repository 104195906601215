import React, { FC, useEffect, useState } from "react";
import { Card, Container, Carousel, Button, Col, Row, Overlay } from "react-bootstrap";
import { journeyOptions } from "../../common/enum/enum";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectNonIndianTours } from "../../../state/selectors/selectTourData";
import {
  SET_TOUR_PACKAGE_ID,
  selectedTourDataDto,
} from "../../../state/actions/types/tourDataActionType";
import { selectScreenSize } from "../../../state/selectors/selectScreenSize";
import clock_icon_red from '../../../Assets/clock-icon-red.png';
import location_icon_red from '../../../Assets/location_icon_red.png';
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const LifetimeJourney: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const nonIndianTours = useSelector(selectNonIndianTours);
  const [tourlist, setTourList] =
    useState<selectedTourDataDto[]>(nonIndianTours);
  useEffect(() => {
    filterData();
    nonIndianTours?.length > 0 && setTourList(filterData());
  }, [nonIndianTours]);

  const filterData = () => {
    return nonIndianTours.filter(
      (tour) => tour.packageName.indexOf("India") === -1
    );
  };
  const screenSize = useSelector(selectScreenSize);


  
  return (
    <Container
      fluid
      className="d-flex flex-column"
      style={styles.lifetimeJourney}
    >
      <h2
        className="bold"
        style={{
          fontSize: 45,
          fontWeight: "400",
          marginBottom: 40,
          color: "#686868",
          textAlign: "center",
          position: "relative",
        }}
      >
        <img
          className="col-md-4 col-sm-6 col-lg-3 col-sx-3 col-9"
          src={require("../../../Assets/incredible.png")}
        />
      </h2>
      {/* <div style={styles.cardContainer} className="row">
        <Col
          lg={4}
          md={6}
          sm={12}
          sx={12}
          style={styles.card}
          className="text-center"
        >
           <div style={styles.cardWrapper}>
          <img
            src="https://drive.google.com/thumbnail?sz=w2000&id=1wOTvo27hpyi3ZvD2cdcJBNHhCdz2W8cF"
            alt="Royal Bharat Edition"
            loading="lazy"
            style={styles.image}
          />
          <p style={styles.cardTitle}>Royal Bharat Edition</p>
          <Col className="w-100 justify-content-center d-flex">
            <button
              onClick={() => history.push(journeyOptions[0].path)}
              style={styles.button}
            >
              View More
            </button>
          </Col>
          </div>
        </Col>
        <Col
          lg={4}
          md={6}
          sm={12}
          sx={12}
          style={styles.card}
          className="text-center"
        >
          <div style={styles.cardWrapper}>
          <img
            src="https://drive.google.com/thumbnail?sz=w2000&id=1wV2RNRvFCy8ew9aqzpeR-zbITwbY5sR1"
            alt="Backpackers Edition"
            loading="lazy"
            style={styles.image}
          />
          <p style={styles.cardTitle}>Backpackers Edition</p>
          <Col className="w-100 justify-content-center d-flex">
            <button
              onClick={() => history.push(journeyOptions[1].path)}
              style={styles.button}
            >
              View More
            </button>
          </Col>
          </div>
        </Col>
        <Col
          lg={4}
          md={6}
          sm={12}
          sx={12}
          style={styles.card}
          className="text-center"
        >
          <div style={styles.cardWrapper}>
          <img
            // src="https://drive.google.com/thumbnail?sz=w2000&id=1wQauWIEPsLvtFOVsHHu7gTYgECXxOgDg"
            src={require("../../../Assets/1000135220.jpg")}
            alt="Combos"
            loading="lazy"
            style={styles.image}
          />
          <p style={styles.cardTitle}>Combos</p>
          <Col className="w-100 justify-content-center d-flex">
            <button
              onClick={() => history.push(journeyOptions[2].path)}
              style={styles.button}
            >
              View More
            </button>
          </Col>
          </div>
        </Col>
      </div> */}

      <div style={styles.cardContainer} className="row">
        {/* Card 1 */}
        <Col
          lg={3}   // 1/3 width on large screens
          md={4}   // 1/3 width on medium screens
          sm={12}  // Full width on small screens
          // xs={12}  // Full width on extra small screens (mobile)
          style={styles.card}
          className="text-center mb-4" // Added margin bottom for spacing between cards on mobile
        >
          <div style={styles.cardWrapper}>
            <img
              src="https://drive.google.com/thumbnail?sz=w2000&id=1wOTvo27hpyi3ZvD2cdcJBNHhCdz2W8cF"
              alt="Royal Bharat Edition"
              loading="lazy"
              style={styles.image}
            />
            <p style={styles.cardTitle}>Royal Bharat Edition</p>
            <Col className="w-100 justify-content-center d-flex">
              <button
                onClick={() => history.push(journeyOptions[0].path)}
                style={styles.button}
              >
                View More
              </button>
            </Col>
          </div>
        </Col>

        {/* Card 2 */}
        <Col
          lg={3}   // 1/3 width on large screens
          md={4}   // 1/3 width on medium screens
          sm={12}  // Full width on small screens
          // xs={12}  // Full width on extra small screens (mobile)
          style={styles.card}
          className="text-center mb-4" // Added margin bottom for spacing between cards on mobile
        >
          <div style={styles.cardWrapper}>
            <img
              src="https://drive.google.com/thumbnail?sz=w2000&id=1wV2RNRvFCy8ew9aqzpeR-zbITwbY5sR1"
              alt="Backpackers Edition"
              loading="lazy"
              style={styles.image}
            />
            <p style={styles.cardTitle}>Backpackers Edition</p>
            <Col className="w-100 justify-content-center d-flex">
              <button
                onClick={() => history.push(journeyOptions[1].path)}
                style={styles.button}
              >
                View More
              </button>
            </Col>
          </div>
        </Col>

        {/* Card 3 */}
        <Col
          lg={3}   // 1/3 width on large screens
          md={4}   // 1/3 width on medium screens
          sm={12}  // Full width on small screens
          // xs={12}  // Full width on extra small screens (mobile)
          style={styles.card}
          className="text-center mb-4" // Added margin bottom for spacing between cards on mobile
        >
          <div style={styles.cardWrapper}>
            <img
              src={require("../../../Assets/1000135220.jpg")}
              alt="Combos"
              loading="lazy"
              style={styles.image}
            />
            <p style={styles.cardTitle}>Combos</p>
            <Col className="w-100 justify-content-center d-flex">
              <button
                onClick={() => history.push(journeyOptions[2].path)}
                style={styles.button}
              >
                View More
              </button>
            </Col>
          </div>
        </Col>
      </div>




      {/* International packages */}
      <h2
        className=""
        style={{
          fontSize: 50,
          fontFamily: "auto",
          fontWeight: "400",
          marginBottom: 42,
          color: "black",
          textAlign: "center",
          position: "relative",
        }}
      >
        <img
          className="col-md-4 col-sm-6 col-lg-3 col-sx-3 col-9"
          src={require("../../../Assets/beyond.png")}
        />
      </h2>{" "}
      <div className='scrolling-wrapper row p-1 flex-row flex-nowrap ' //w-90 align-self-center p-4
      style={{
        gap: "25px",
      }}
      >
        {tourlist.map((tour, index: number) => {
          return (
            <Col
              md={6}
              lg={4}
              sx={6}
              sm={12}
              key={index}
              className={`expandCard col-14 d-inline-block position-relative`}
            >
              <Card
                className={`${styles.carouselCard} `}
                style={{ minWidth: "80%",
                  width: "100%",
                  borderRadius: "15px",
                  overflow:"visible !important"
                 }}
              >
                <Card.Img
                  variant="top"
                  style={{ minHeight: "270px" }}
                  src={
                    tour?.imageUri?.length > 0 && tour.imageUri[0] !== ""
                      ? `https://drive.google.com/thumbnail?sz=w2000&id=${tour.imageUri[0]}`
                      : "https://drive.google.com/thumbnail?sz=w2000&id=1j8giF6uvrDsI-yfMYZFWxdBGe0wirl6w"
                  }
                  alt={tour.title}
                  // className={styles.cardImage}
                />
                <Card.Body
                  className={`align-items-between d-grid ${styles.cardTextOverlay}`}
                  style={{ minHeight: "229px" }}
                >
                  <div
  className="d-flex w-100 align-items-center justify-content-between align-content-between"
  style={{ position: "relative" }}
>
  {/* Card Title */}
  <Card.Title
    className={`m-0 p-0 ${
      screenSize?.screenSize < 767
        ? "normal"
        : screenSize?.screenSize > 767 && screenSize?.screenSize < 969
        ? "fs-medium"
        : "fs-auto"
    }`}
    style={{
      zIndex: "1",
      fontSize: "20px", // Adjust size dynamically if needed
      fontWeight: "700",
      color: "#064055",
      whiteSpace: "normal", // Allow the title to wrap
      lineHeight: "1.2", // Adjust spacing between lines
      maxWidth: "75%", // Prevent the title from expanding too much
      overflowWrap: "break-word", // Handle very long words
      textAlign: "left"
    }}
  >
    <span>{tour.title}</span>
  </Card.Title>

  {/* Subtitle Section */}
  <Card.Subtitle
    style={{
      fontSize: "13px",
      fontWeight: "400",
      color: "#064055",
      borderRadius: "3px",
      textAlign: "center",
      alignContent: "center",
      marginLeft: "auto", // Push the subtitle to the right
      display: "flex",
      alignItems: "center",
      gap: "5px", // Space between the icon and text
    }}
  >
    <img
      src={clock_icon_red}
      alt="clock icon"
      style={{
        marginBottom: "2px",
        width: "15px", // Adjust size if needed
        height: "15px",
      }}
    />
    <span>
      {tour.noOfDays} <small>days</small>
    </span>
  </Card.Subtitle>

  {/* Divider */}
  <hr
    style={{
      position: "absolute",
      bottom: "0",
      left: "0",
      width: "100%",
      zIndex: "0",
      border: "0.5px solid #D0D0D0",
    }}
  />
</div>

                  <Col className="pt-2">
                    <div className="d-flex p-0 m-0 flex-row justify-content-between">
                      <Row className="list-unstyled">
                        <FontAwesomeIcon
                        icon={faLocationDot}
                        size="sm"
                        className="col px-0 mx-0"
                        style={{
                          marginTop: "6px",
                          color: "#ea3560",
                          paddingRight: "2px"
                        }}
                        />
                        {tour.destinations
                          .slice(0, 4)
                          .map((destination, index) => (
                            <li
                              key={index}
                              style={{
                                fontSize: 13,
                                fontWeight: "400",
                                color: "#818181",
                                marginTop: 5,
                              }}
                              className="col px-0 mx-0"
                            >
                              {index !== 0 && (
                                <span
                                  style={{
                                    color: "#75abdc",
                                  }}
                                >
                                  ,
                                </span>
                              )}{" "}
                              {/* {index !== 0 && (
                                <span> </span>
                              )}
                               */}
                              
                              {destination}
                            </li>
                          ))}
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "400",
                            color: "#818181",
                            marginTop: 5,

                          }}
                          className="col"
                        >
                          {tour.destinations.length > 5 &&
                            tour.destinations.length - 5 + "+"}
                        </span>
                      </Row>
                    </div>
                    {/* </Card.Text> */}
                    <hr
                      style={{
                        border: "0.5px solid #D0D0D0",
                        width: "100%",
                        margin: "10px 0",
                      }}
                    />
                    <div className="d-flex justify-content-between align-items-center">
                      <p
                        className="d-flex align-items-center p-0 my-2"
                        style={{
                          fontSize: 16,
                          fontWeight: "700",
                          color: "#05A1A5",
                        }}
                      >
                        <span>
                          <select
                            style={{
                              WebkitAppearance: "none",
                              MozAppearance: "none",
                              background: "transparent",
                              // fontSize: "16px",
                              fontWeight: "700",
                              color: "#064055",
                            }}
                            className=" p-0 pointer border border-0 w-100 small DM Sans"
                            aria-label="Default select example"
                          >
                            {tour?.packagePrice?.map(
                              (price: string, index: number) => (
                                <option
                                  key={index}
                                  value={price}
                                  className="small DM Sans bg-white"
                                  style={{
                                    // fontSize: "16px",
                                    fontWeight: "700",
                                    color: "#064055",
                                  }}
                                >
                                  {price}
                                </option>
                              )
                            )}
                          </select>
                        </span>
                        <span
                          className="small DM Sans bg-white"
                          style={{
                            // fontSize: 16,
                            fontWeight: "700",
                            color: "#064055",
                          }}
                        >/ Person
                        </span>
                      </p>
                      <Button
                        style={{
                          backgroundColor: "#EA3560",
                          fontWeight: "700",
                          fontSize: 15,
                          borderRadius: "25px",
                          borderWidth: 0,
                        }}
                        onClick={() => {
                          dispatch({
                            type: SET_TOUR_PACKAGE_ID,
                            payload: tour.packageId,
                          });
                          history.push(`/tour-detail:${tour.packageId}`);
                        }}
                      >
                        View More
                      </Button>
                    </div>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </div>
    </Container>

    
  );
};

// const styles = {
//   lifetimeJourney: {
//     textAlign: "center" as const,
//     padding: "20px",
//   },
//   heading: {
//     marginBottom: "20px",
//   },
//   cardContainer: {
//     display: "flex",
//     flexWrap: "wrap" as const,
//     justifyContent: "space-between",
//     gap: "20px", // Add gap for spacing between cards
//     marginBottom: "40px",
//   },
//   carouselCard: {
//     // width: 382,
//     height: 370,
//     position: "relative" as "relative", // Ensure position is explicitly typed
//     margin: 0,
//     opacity: 1,
//   },
//   cardImage: {
//     width: "100%",
//     height: 250,
//     objectFit: "cover" as "cover", // Ensure objectFit is explicitly typed
//     padding: 0,
//     margin: 0,
//   },
//   cardTextOverlay: {
//     position: "relative" as "relative", // Ensure position is explicitly typed
//     padding: 10,
//     color: "black",
//     background: "white",
//     border: "1px solid black" as "1px solid black", // Ensure border is properly typed
//   },
//   card: {
//     backgroundColor: "#fff",
//     borderRadius: "12px",
//     textAlign: "center" as const,
//     padding: "0px",
//     boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
//     overflow: "hidden",
//     margin: "0 auto", // Center card when in column mode
//     flex: "1 1 calc(33.33% - 20px)", // Responsive flex sizing for desktop
//   },
//   image: {
//     width: "100%",
//     height: "100%",
//     objectFit: "cover" as const,
//     display: "block",
//   },
//   cardWrapper: {
//     display: "flex",
//     flexDirection: "column" as const,
//     alignItems: "center",
//     justifyContent: "center",
//     width: "100%",
//   },
//   cardTitle: {
//     fontSize: "20px",
//     fontWeight: "600",
//     color: "#333",
//     marginBottom: "15px 0px",
//   },
//   button: {
//     fontSize: "15px",
//     fontWeight: "500",
//     color: "#fff",
//     border: "none",
//     padding: "3px 16px",
//     cursor: "pointer",
//     marginBottom: "20px",
//     backgroundColor: "#EA3560",
//     borderRadius: "28px",
//     // boxShadow: "0 4px 8px rgba(248, 87, 87, 0.4)",
//     transition: "all 0.3s ease",
//   },
//   buttonHover: {
//     backgroundColor: "#e04646",
//   },
// };


const styles = {
  lifetimeJourney: {
    textAlign: "center" as const,
    padding: "20px",
  },
  heading: {
    marginBottom: "20px",
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    // gap: "20px", // Add gap for spacing between cards
    marginBottom: "40px",
  },
  carouselCard: {
    height: 370,
    position: "relative" as "relative",
    margin: 0,
    opacity: 1,
  },
  cardImage: {
    width: "100%",
    height: 250,
    objectFit: "cover" as "cover",
    padding: 0,
    margin: 0,
  },
  cardTextOverlay: {
    position: "relative" as "relative",
    padding: 10,
    color: "black",
    background: "white",
    border: "1px solid black" as "1px solid black",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "20px",
    textAlign: "center" as const,
    padding: "0px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    // overflow: "hidden",
    margin: "0 auto", // Center card when in column mode
    // flex: "1 1 calc(33.33% - 20px)", // Default for large screens (1/3 width)
    // transition: "transform 0.3s ease",  // Add transition for scaling effect
    
    "@media (max-width: 768px)": {
      flex: "1 1 100%", // For small screens, take up full width
      // marginRight: "10px"
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover" as const,
    display: "block",
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#333",
    marginBottom: "15px 0px",
    padding: "10px"
  },
  button: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#fff",
    border: "none",
    padding: "3px 16px",
    cursor: "pointer",
    marginBottom: "20px",
    backgroundColor: "#EA3560",
    borderRadius: "28px",
    transition: "all 0.3s ease",
  },
  buttonHover: {
    backgroundColor: "#e04646",
  },

};

export default LifetimeJourney;
